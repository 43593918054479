import normalizeUrl from './normalize-url';

/**
 * Returns a clean annotation path
 * If url, returns normalized url
 * If it's a Pdf, will return "Page 1" etc
 * If image, returns image name
 *
 * @export
 * @param {*} { annotation, canvasAssets }
 * @returns
 */
export default function getAnnotationPath(annotation, canvasAssets) {
    const isPdf = canvasAssets[annotation?.CanvasAssetId]?.pageThumbnailUrls;

    const path = annotation.CanvasAssetId
        ? isPdf
            ? `Page ${annotation.canvasAssetPageIndex + 1}`
            : canvasAssets[annotation.CanvasAssetId].name
        : normalizeUrl(annotation.path);

    return path;
}
