import { PLANS_ADD } from './types';

const plans = (state = {}, action) => {
    switch (action.type) {
        case PLANS_ADD: {
            const plans = { ...state };
            action.payload.plans.forEach((plan) => {
                plans[plan.id] = { ...plan };
            });
            return plans;
        }
        default:
            return state;
    }
};

export default plans;
