import API from 'lib/api';
import { CANVAS_USERS_ADD, CANVAS_USERS_UPDATE } from './types';

export function addCanvasUsers(canvasUsers) {
    return {
        type: CANVAS_USERS_ADD,
        payload: { canvasUsers }
    };
}

export function updateCanvasUsers(canvasUsers) {
    return {
        type: CANVAS_USERS_UPDATE,
        payload: { canvasUsers }
    };
}

export function getCanvasUser(userId, canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/users/${userId}`, 'get', null, getState().auth).then((canvasUser) => {
            dispatch(addCanvasUsers([canvasUser]));
            return canvasUser;
        });
}

// Currently, the only data we have is a user's notifications preference
// Thus this parameter expects { notificationsPreference: 'all' }
export function modifyCanvasUser(userId, canvasId, data) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/users/${userId}`, 'put', data, getState().auth).then((canvasUser) => {
            dispatch(updateCanvasUsers([canvasUser]));
            return canvasUser;
        });
}
