import API from 'lib/api';
import * as analytics from 'lib/analytics';
import {
    BILLING_CARD_MODAL_HIDE,
    BILLING_CARD_MODAL_SHOW,
    BILLING_COUPON_MODAL_HIDE,
    BILLING_COUPON_MODAL_SHOW,
    BILLING_DATA_SET,
    BILLING_EMAIL_MODAL_HIDE,
    BILLING_EMAIL_MODAL_SHOW,
    BILLING_INVOICE_SELECT,
    BILLING_OFFBOARDING_SURVEY_MODAL_HIDE,
    BILLING_OFFBOARDING_SURVEY_MODAL_SHOW,
    BILLING_PLAN_FOR_SWITCH_SELECT,
    BILLING_PLAN_SET,
    BILLING_SWITCH_PLAN_MODAL_HIDE,
    BILLING_SWITCH_PLAN_MODAL_SHOW,
    BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE,
    BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW,
    BILLING_UPGRADE_TO_PAID_MODAL_HIDE,
    BILLING_UPGRADE_TO_PAID_MODAL_SHOW
} from './types';
import { setLoggedInUser, updateLoggedInUser } from '../auth/actions';

export function fetchBillingData() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/users/${auth.user.id}/customer/billing-data`, 'get', null, auth).then((billingData) => {
            dispatch({
                type: BILLING_DATA_SET,
                payload: { ...billingData }
            });
        });
    };
}

export function fetchBillingCustomer() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call('/users/me/customer', 'get', null, auth).then((customer) => {
            dispatch({
                type: BILLING_DATA_SET,
                payload: { customer }
            });
        });
    };
}

export function switchPlan(plan, userId, teamName) {
    return (dispatch, getState) =>
        API.call(`/users/${userId}/customer/plan`, 'put', { plan, teamName }, getState().auth).then((user) =>
            dispatch(setLoggedInUser(user))
        );
}

export function addBillingCard({ token }) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/users/${auth.user.id}/customer/card`, 'post', { source: token }, auth).then((customer) => {
            dispatch(fetchBillingData());
            return dispatch(updateLoggedInUser({ Customer: customer }));
        });
    };
}

export function addBillingCoupon({ coupon }) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/users/${auth.user.id}/customer/coupon`, 'post', { coupon }, auth).then(() =>
            dispatch(fetchBillingData())
        );
    };
}

export function cancelBillingPlan() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/users/${auth.user.id}/customer/subscription`, 'delete', null, auth).then((customer) => {
            analytics.cancelPlan();
            dispatch(fetchBillingData());
            return dispatch(updateLoggedInUser({ Customer: customer }));
        });
    };
}

export function selectBillingInvoice(invoiceId) {
    return {
        type: BILLING_INVOICE_SELECT,
        payload: {
            invoiceId
        }
    };
}

export function showBillingCardModal() {
    return {
        type: BILLING_CARD_MODAL_SHOW,
        payload: null
    };
}

export function hideBillingCardModal() {
    return {
        type: BILLING_CARD_MODAL_HIDE,
        payload: null
    };
}

export function showBillingCouponModal() {
    return {
        type: BILLING_COUPON_MODAL_SHOW,
        payload: null
    };
}

export function hideBillingCouponModal() {
    return {
        type: BILLING_COUPON_MODAL_HIDE,
        payload: null
    };
}

export function showBillingEmailModal() {
    return {
        type: BILLING_EMAIL_MODAL_SHOW,
        payload: null
    };
}

export function hideBillingEmailModal() {
    return {
        type: BILLING_EMAIL_MODAL_HIDE,
        payload: null
    };
}

export function setBillingPlan(plan) {
    return {
        type: BILLING_PLAN_SET,
        payload: { plan }
    };
}

export function fetchBillingPlan(planId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/plans/${planId}`, 'get', null, auth).then((plan) => dispatch(setBillingPlan(plan)));
    };
}

export function selectBillingPlansForSwitch(oldPlan = null, newPlan = null) {
    return {
        type: BILLING_PLAN_FOR_SWITCH_SELECT,
        payload: { oldPlan, newPlan }
    };
}

export function showSwitchPlanModal() {
    return {
        type: BILLING_SWITCH_PLAN_MODAL_SHOW,
        payload: null
    };
}

export function hideSwitchPlanModal() {
    return {
        type: BILLING_SWITCH_PLAN_MODAL_HIDE,
        payload: null
    };
}

export function showSwitchPlanSuccessModal() {
    return {
        type: BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW,
        payload: null
    };
}

export function hideSwitchPlanSuccessModal() {
    return {
        type: BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE,
        payload: null
    };
}

export function showBillingUpgradeToPaidModal() {
    return {
        type: BILLING_UPGRADE_TO_PAID_MODAL_SHOW,
        payload: null
    };
}

export function hideBillingUpgradeToPaidModal() {
    return {
        type: BILLING_UPGRADE_TO_PAID_MODAL_HIDE,
        payload: null
    };
}

export function showOffboardingSurveyModal() {
    return {
        type: BILLING_OFFBOARDING_SURVEY_MODAL_SHOW,
        payload: null
    };
}

export function hideOffboardingSurveyModal() {
    return {
        type: BILLING_OFFBOARDING_SURVEY_MODAL_HIDE,
        payload: null
    };
}
