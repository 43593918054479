import { CANVASES_ADD } from '../canvases/types';
import { CANVAS_REVIEWS_ADD } from './types';

const canvasReviews = (state = {}, action) => {
    switch (action.type) {
        case CANVAS_REVIEWS_ADD: {
            const addedCanvasReviews = { ...state };
            action.payload.canvasReviews.forEach((canvasReview) => {
                addedCanvasReviews[canvasReview.id] = { ...canvasReview };
            });
            return addedCanvasReviews;
        }
        case CANVASES_ADD: {
            const addedCanvasReviews = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.CanvasReviews) {
                    return;
                }

                canvas.CanvasReviews.forEach((canvasReview) => {
                    addedCanvasReviews[canvasReview.id] = { ...canvasReview };
                });
            });
            return addedCanvasReviews;
        }
        default:
            return state;
    }
};

export default canvasReviews;
