import API from 'lib/api';
import { FILE_ATTACHMENTS_ADD, FILE_ATTACHMENTS_REMOVE } from './types';

export function addFileAttachments(fileAttachments) {
    return {
        type: FILE_ATTACHMENTS_ADD,
        payload: { fileAttachments }
    };
}

export function removeFileAttachments(fileAttachments) {
    return {
        type: FILE_ATTACHMENTS_REMOVE,
        payload: { fileAttachments }
    };
}

export function createFileAttachment(attachment) {
    return (dispatch, getState) =>
        API.upload('/file-attachments', 'post', { attachment }, getState().auth).then((fileAttachment) => {
            dispatch(addFileAttachments([fileAttachment]));
            return fileAttachment;
        });
}

export function deleteFileAttachment(fileAttachment, annotationId = null) {
    return (dispatch, getState) =>
        API.call(`/file-attachments/${fileAttachment.id}`, 'delete', null, getState().auth).then(() => {
            dispatch(
                removeFileAttachments([
                    {
                        ...fileAttachment,
                        AnnotationId: annotationId || fileAttachment.AnnotationId
                    }
                ])
            );
            return fileAttachment;
        });
}
