import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { getCanvasAssetsToDisplay } from 'store/selectors';
import { CANVAS_ASSETS_ADD, CANVAS_ASSETS_REMOVE, CANVAS_ASSETS_UPDATE } from './types';
import { removeAnnotations } from '../annotations/actions';
import { addCanvases } from '../canvases/actions';
import { addDashboardCanvases } from '../dashboard-canvases/actions';
import { selectFeedbackLinkCanvasAsset, selectFeedbackLinkAnnotation } from '../ui-feedback-link/actions';

export function addCanvasAssets(canvasAssets) {
    return {
        type: CANVAS_ASSETS_ADD,
        payload: { canvasAssets }
    };
}

export function removeCanvasAssets(canvasAssets) {
    return {
        type: CANVAS_ASSETS_REMOVE,
        payload: { canvasAssets }
    };
}

export function updateCanvasAssets(canvasAssets) {
    return {
        type: CANVAS_ASSETS_UPDATE,
        payload: { canvasAssets }
    };
}

export function createCanvasAsset(canvasId, canvasAssetFile, position, revisionId) {
    return (dispatch, getState) => {
        const { auth, ui } = getState();
        const selectedFolderId = ui.dashboard?.selectedFolderId;

        return API.upload(
            `/canvas-assets`,
            'post',
            { id: canvasId, canvasAssetFile, position, revisionId, folderId: selectedFolderId },
            auth
        ).then(({ canvasAsset, canvas }) => {
            analytics.addCanvasAsset(canvas);
            dispatch(addCanvasAssets([canvasAsset]));

            // Mostly for email canvases, where the canvas is created
            // at the same time as the canvas asset (unlike for PDFs and images)
            if (canvas && !canvasId) {
                analytics.createCanvas(canvas);
                dispatch(addCanvases([canvas]));
                dispatch(addDashboardCanvases([canvas]));
            }
            return canvasAsset;
        });
    };
}

// TODO: Should this go here? Maybe renamed and placed in a feature/ui folder.
// Alternatively, some of the logic here could potentially go inside reducers?
export function realtimeDeletedCanvasAsset(deletedCanvasAsset, deletedAnnotations) {
    return (dispatch, getState) => {
        const state = getState();
        const {
            ui: {
                feedbackLink: { selectedAnnotationId, canvasAssetId: selectedCanvasAssetId }
            }
        } = state;

        const remainingCanvasAssets = getCanvasAssetsToDisplay(state).filter(
            (canvasAsset) => canvasAsset.id !== deletedCanvasAsset.id
        );

        let newSelectedCanvasAssetId = selectedCanvasAssetId;

        // Reset selected canvas image
        if (deletedCanvasAsset.id === selectedCanvasAssetId) {
            newSelectedCanvasAssetId = remainingCanvasAssets[0]?.id;
            dispatch(selectFeedbackLinkCanvasAsset(newSelectedCanvasAssetId));
        }

        // Reset selected annotation
        if (deletedAnnotations.map((annotation) => annotation.id).includes(selectedAnnotationId)) {
            dispatch(selectFeedbackLinkAnnotation(null));
        }

        dispatch(removeAnnotations(deletedAnnotations));
        dispatch(removeCanvasAssets([deletedCanvasAsset]));

        // Reset #num in URL
        if (newSelectedCanvasAssetId && remainingCanvasAssets.length > 0) {
            dispatch(selectFeedbackLinkCanvasAsset(newSelectedCanvasAssetId));
        }
    };
}

export function deleteCanvasAsset(canvasId, canvasAssetIdToDelete) {
    return (dispatch, getState) => {
        const { canvases, auth } = getState();
        const canvas = canvases[canvasId];
        return API.call(`/canvases/${canvasId}/assets/${canvasAssetIdToDelete}`, 'delete', null, auth).then(
            ({ canvasAsset, annotations }) => {
                analytics.deleteCanvasAsset(canvas, canvasAsset.type);
                return dispatch(realtimeDeletedCanvasAsset(canvasAsset, annotations));
            }
        );
    };
}

export function replaceCanvasAsset(canvasId, canvasAssetIdToReplace, canvasAssetFile) {
    return (dispatch, getState) => {
        const { canvases, auth } = getState();
        const canvas = canvases[canvasId];

        return API.upload(
            `/canvases/${canvasId}/assets/${canvasAssetIdToReplace}`,
            'post',
            { canvasAssetFile },
            auth
        ).then((canvasAsset) => {
            analytics.replaceCanvasAsset(canvas, canvasAsset.type);
            dispatch(updateCanvasAssets([canvasAsset]));
            return canvasAsset;
        });
    };
}

export function reorderCanvasAssets(canvasId, canvasAssets) {
    return (dispatch, getState) => {
        const { auth } = getState();

        // Locally persist changes first
        dispatch(updateCanvasAssets(canvasAssets));

        // Persist to the backend
        return API.call(
            `/canvases/${canvasId}/assets/reorder`,
            'post',
            {
                canvasAssets: canvasAssets.map((canvasAsset) => ({
                    id: canvasAsset.id,
                    position: canvasAsset.position
                }))
            },
            auth
        ).then((canvasAssets) => dispatch(updateCanvasAssets(canvasAssets)));
    };
}
