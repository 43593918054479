import { ANNOTATIONS_ADD } from '../annotations/types';
import { ANNOTATION_REPLIES_ADD, ANNOTATION_REPLIES_UPDATE, ANNOTATION_REPLIES_REMOVE } from './types';

const annotationReplies = (state = {}, action) => {
    switch (action.type) {
        case ANNOTATIONS_ADD:
            const existingReplies = { ...state };
            action.payload.annotations.forEach((annotation) => {
                if (!annotation.AnnotationReplies) {
                    return;
                }

                annotation.AnnotationReplies.forEach((reply) => {
                    existingReplies[reply.id] = { ...reply };
                    existingReplies[reply.id].FileAttachments = reply.FileAttachments
                        ? reply.FileAttachments.map((attachment) => attachment.id)
                        : [];
                });
            });
            return existingReplies;
        case ANNOTATION_REPLIES_ADD:
            const addedReplies = { ...state };
            action.payload.replies.forEach((reply) => {
                addedReplies[reply.id] = { ...reply };
                addedReplies[reply.id].FileAttachments = reply.FileAttachments
                    ? reply.FileAttachments.map((attachment) => attachment.id)
                    : [];
            });
            return addedReplies;
        case ANNOTATION_REPLIES_UPDATE:
            const updatedReplies = { ...state };
            action.payload.replies.forEach((reply) => {
                updatedReplies[reply.id] = { ...updatedReplies[reply.id], ...reply };
            });
            return updatedReplies;
        case ANNOTATION_REPLIES_REMOVE: {
            const replies = { ...state };
            action.payload.replies.forEach((reply) => {
                delete replies[reply.id];
            });
            return replies;
        }
        default:
            return state;
    }
};

export default annotationReplies;
