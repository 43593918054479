class AppError extends Error {
    constructor(message, name, statusCode, meta) {
        super(message);

        this.message = message;
        this.name = name || 'AppError';
        this.statusCode = statusCode || 400;
        this.appError = true;
        this.meta = meta;

        if (typeof Error.captureStackTrace !== 'undefined') {
            Error.captureStackTrace(this, AppError);
        }
    }
}

function NotFound(message = 'Not found.') {
    return new AppError(message, 'NotFound', 404);
}

export default {
    AppError,
    NotFound
};
