import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { AUTH_USER_LOGOUT } from './auth/types';

// Entity reducers
import annotationReplies from './annotation-replies/reducer';
import annotations from './annotations/reducer';
import auth from './auth/reducer';
import canvases from './canvases/reducer';
import canvasAssets from './canvas-assets/reducer';
import canvasDeadlineReminders from './canvas-deadline-reminders/reducer';
import canvasReviews from './canvas-reviews/reducer';
import canvasRevisions from './canvas-revisions/reducer';
import canvasUsers from './canvas-users/reducer';
import dashboardCanvases from './dashboard-canvases/reducer';
import dashboardFolders from './dashboard-folders/reducer';
import events from './events/reducer';
import externalAccounts from './external-accounts/reducer';
import fileAttachments from './file-attachments/reducer';
import folders from './folders/reducer';
import labels from './labels/reducer';
import plans from './plans/reducer';
import teamInvites from './team-invites/reducer';
import users from './users/reducer';

// UI reducers
import billing from './ui-billing/reducer';
import dashboard from './ui-dashboard/reducer';
import feedbackLink from './ui-feedback-link/reducer';
import settings from './ui-settings/reducer';
import signup from './ui-signup/reducer';

const appReducer = combineReducers({
    annotationReplies,
    annotations,
    auth,
    canvasAssets,
    canvases,
    canvasDeadlineReminders,
    canvasReviews,
    canvasRevisions,
    canvasUsers,
    dashboardCanvases,
    dashboardFolders,
    events,
    externalAccounts,
    fileAttachments,
    form: formReducer,
    folders,
    labels,
    plans,
    teamInvites,
    ui: combineReducers({
        billing,
        dashboard,
        feedbackLink,
        settings,
        signup
    }),
    users
});

const rootReducer = (state, action) => appReducer(action.type === AUTH_USER_LOGOUT ? undefined : state, action);

export default rootReducer;
