import { Component } from 'react';
import cx from 'classnames';
import manager from './toasts-manager';
import InfoToast from './InfoToast';
import SuccessToast from './SuccessToast';
import ErrorToast from './ErrorToast';
import styles from './toasts.scss';

/**
 * Handles the rendering of all toasts, powered by the data within the toasts manager.
 *
 * @export
 * @class Toasts
 * @extends {Component}
 */
export default class Toasts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toasts: []
        };

        this.handleToastsUpdate = this.handleToastsUpdate.bind(this);
    }

    componentDidMount() {
        manager.addListener(this.handleToastsUpdate);
    }

    componentWillUnmount() {
        manager.removeListener(this.handleToastsUpdate);
    }

    handleToastsUpdate(toasts) {
        this.setState({
            toasts
        });
    }

    render() {
        const { bottomPadding, attachRight } = this.props;
        const { toasts } = this.state;

        return (
            <div
                className={cx('toasts-wrapper', {
                    'bottom-padding': bottomPadding,
                    'attach-right': attachRight,
                    'has-toasts': toasts.length > 0
                })}
            >
                <style jsx>{styles}</style>
                <div className="toasts">
                    {toasts.map((toast) => (
                        <div
                            className={cx('toast-wrapper', {
                                'animating-in': toast.animatingIn,
                                'animating-out': toast.animatingOut
                            })}
                            key={toast.id}
                        >
                            {toast.type === 'info' && <InfoToast toast={toast} />}
                            {toast.type === 'success' && <SuccessToast toast={toast} />}
                            {toast.type === 'error' && <ErrorToast toast={toast} />}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
