import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { TEAM_INVITES_ADD, TEAM_INVITES_REMOVE } from './types';
import { setSignupInvite } from '../ui-signup/actions';
import { setLoggedInUser } from '../auth/actions';
import { getDashboardCanvases } from '../canvases/actions';
import { getDashboardFolders } from '../folders/actions';

export function addTeamInvites(invites = []) {
    return {
        type: TEAM_INVITES_ADD,
        payload: { invites }
    };
}

export function removeTeamInvites(invites = []) {
    return {
        type: TEAM_INVITES_REMOVE,
        payload: { invites }
    };
}

export function fetchTeamInvites(teamId) {
    return (dispatch, getState) =>
        API.call(`/teams/${teamId}/invites`, 'get', null, getState().auth).then((invites) =>
            dispatch(addTeamInvites(invites))
        );
}

export function fetchInviteByCode(code) {
    return (dispatch, getState) =>
        API.call(`/invites?code=${code}`, 'get', null, getState().auth).then((invite) => {
            dispatch(addTeamInvites([invite]));
            return invite;
        });
}

export function fetchInviteByEmail(email) {
    return (dispatch, getState) =>
        API.call(`/invites?email=${encodeURIComponent(email)}`, 'get', null, getState().auth).then((invite) => {
            dispatch(addTeamInvites([invite]));
            return invite;
        });
}

export function sendTeamInvites(team, emails) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${team.id}/invites`, 'post', { emails }, auth).then((invites = []) => {
            invites.forEach(() => {
                analytics.sendTeamInvite();
            });
            dispatch(addTeamInvites(invites));
            return invites;
        });
    };
}

export function resendTeamInvite(teamId, inviteId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${teamId}/invites/${inviteId}/resend`, 'post', null, auth);
    };
}

export function deleteTeamInvite(teamId, inviteId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${teamId}/invites/${inviteId}`, 'delete', null, auth).then((invite) =>
            dispatch(removeTeamInvites([invite]))
        );
    };
}

export function declineTeamInvite(inviteId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/invites/${inviteId}/decline`, 'post', null, auth).then((invite) => {
            analytics.teamInviteResponse(false, 'dashboard');
            dispatch(setSignupInvite(null));
            return dispatch(removeTeamInvites([invite]));
        });
    };
}

export function acceptTeamInvite(inviteId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/invites/${inviteId}/accept`, 'post', null, auth)
            .then((user) => {
                analytics.teamInviteResponse(true, 'dashboard');
                dispatch(setSignupInvite(null));
                dispatch(removeTeamInvites([{ id: inviteId }]));
                dispatch(setLoggedInUser(user));
                return dispatch(getDashboardCanvases());
            })
            .then(() => dispatch(getDashboardFolders()).catch(() => null)); // eslint-disable-line promise/no-nesting
    };
}
