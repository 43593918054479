import trimEnd from 'lodash/trimEnd';

/**
 * Returns whether two URL paths are the same,
 * ignoring both trailing slashes and query string.
 *
 * Note: Also occasionally ignoring hash in special circumstances.
 *
 * @export
 * @param {*} pathA
 * @param {*} pathB
 * @returns
 */
export default function pathsAreEqual(pathA, pathB) {
    let [pathAWithoutQuery] = (pathA || '').split('?');
    let [pathBWithoutQuery] = (pathB || '').split('?');

    if (pathAWithoutQuery.includes('#') && !pathAWithoutQuery.includes('#/')) {
        [pathAWithoutQuery] = pathAWithoutQuery.split('#');
    }

    if (pathBWithoutQuery.includes('#') && !pathBWithoutQuery.includes('#/')) {
        [pathBWithoutQuery] = pathBWithoutQuery.split('#');
    }

    return trimEnd(pathAWithoutQuery, '/') === trimEnd(pathBWithoutQuery, '/');
}
