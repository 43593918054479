/**
 * Manages the data and animation logic powering the on-screen toasts.
 *
 * @class ToastsManager
 */
class ToastsManager {
    constructor() {
        this.toasts = [];
        this.listeners = [];
        this.nextId = 1;
        this.animationLength = 300;
    }

    /**
     * Create a new toast.
     *
     * @param {*} type | string
     * @param {*} message | string / React element
     * @param {*} action | null / { onClick: function, name: string }
     * @param {*} timeout
     * @memberof ToastsManager
     */
    create({ type, message, action = null, timeout = 5000 }) {
        if (!type) {
            throw new Error('Missing toast type!');
        }

        if (!message) {
            throw new Error('Missing toast message!');
        }

        const createdToast = {
            id: this.nextId,
            type,
            message,
            action,
            animatingIn: true,
            animatingOut: false
        };
        this.toasts.push(createdToast);
        this.nextId++;

        const fullTimeout = timeout + this.animationLength * 2;

        this.notifyListeners();

        // Animate in
        setTimeout(() => {
            const updatedToast = { ...createdToast, animatingIn: false, animatingOut: false };

            const index = this.toasts.map((toast) => toast.id).indexOf(updatedToast.id);
            if (index !== -1) {
                this.toasts[index] = updatedToast;
            }

            this.notifyListeners();
        }, this.animationLength);

        const animateOutFunction = () => {
            // Animate out
            const updatedToast = { ...createdToast, animatingIn: false, animatingOut: true };

            const index = this.toasts.map((toast) => toast.id).indexOf(updatedToast.id);
            if (index !== -1) {
                this.toasts[index] = updatedToast;
            }

            this.notifyListeners();

            setTimeout(() => {
                this.toasts = this.toasts.filter((toast) => toast.id !== createdToast.id);
                this.notifyListeners();
            }, this.animationLength);
        };

        const animateOutTimeout = setTimeout(animateOutFunction, fullTimeout);

        // Cancel the timeout and trigger it right away
        if (action && action.onClick) {
            const originalActionOnClick = action.onClick;
            createdToast.action.onClick = () => {
                clearTimeout(animateOutTimeout);
                originalActionOnClick();
                animateOutFunction();
            };
        }
    }

    /**
     * Notify listeners of change to toasts list
     *
     * @memberof ToastsManager
     */
    notifyListeners() {
        this.listeners.forEach((listener) => {
            listener([...this.toasts]);
        });
    }

    /**
     * Add a listener to watch changes to toasts
     *
     * @param {*} addedListener
     * @memberof ToastsManager
     */
    addListener(addedListener) {
        this.listeners.push(addedListener);
    }

    /**
     * Remove a listener
     *
     * @param {*} removedListener
     * @memberof ToastsManager
     */
    removeListener(removedListener) {
        this.listeners = this.listeners.filter((listener) => listener !== removedListener);
    }
}

const singleton = new ToastsManager();
export default singleton;
