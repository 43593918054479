import uniq from 'lodash/uniq';
import { ANNOTATIONS_ADD, ANNOTATIONS_REMOVE } from 'store/annotations/types';
import { CANVAS_ASSETS_ADD, CANVAS_ASSETS_REMOVE } from 'store/canvas-assets/types';
import { CANVAS_REVIEWS_ADD } from 'store/canvas-reviews/types';
import { CANVAS_REVISIONS_ADD, CANVAS_REVISIONS_REMOVE } from 'store/canvas-revisions/types';
import { CANVAS_DEADLINE_REMINDERS_ADD, CANVAS_DEADLINE_REMINDERS_REMOVE } from 'store/canvas-deadline-reminders/types';
import { CANVASES_ADD, CANVASES_REMOVE, CANVASES_UPDATE } from './types';

const canvases = (state = {}, action) => {
    switch (action.type) {
        case CANVASES_ADD: {
            const addedCanvases = { ...state };
            action.payload.canvases.forEach((canvas) => {
                addedCanvases[canvas.id] = { ...canvas };
                addedCanvases[canvas.id].Annotations = [];
                addedCanvases[canvas.id].CanvasAssets = canvas.CanvasAssets
                    ? canvas.CanvasAssets.map((image) => image.id)
                    : [];
                addedCanvases[canvas.id].CanvasRevisions = canvas.CanvasRevisions
                    ? canvas.CanvasRevisions.map((revision) => revision.id)
                    : [];
                addedCanvases[canvas.id].CanvasReviews = canvas.CanvasReviews
                    ? canvas.CanvasReviews.map((review) => review.id)
                    : [];
                addedCanvases[canvas.id].CanvasDeadlineReminders = canvas.CanvasDeadlineReminders
                    ? canvas.CanvasDeadlineReminders.map((deadlineReminder) => deadlineReminder.id)
                    : [];
            });
            return addedCanvases;
        }
        case CANVASES_REMOVE: {
            const removedCanvases = { ...state };
            action.payload.canvases.forEach((canvas) => {
                delete removedCanvases[canvas.id];
            });
            return removedCanvases;
        }
        case CANVASES_UPDATE: {
            const updatedCanvases = { ...state };
            action.payload.canvases.forEach((canvas) => {
                const previousCanvas = state[canvas.id];
                updatedCanvases[canvas.id] = { ...updatedCanvases[canvas.id], ...canvas };
                updatedCanvases[canvas.id].CanvasAssets = canvas.CanvasAssets
                    ? canvas.CanvasAssets.map((image) => image.id)
                    : previousCanvas
                    ? previousCanvas.CanvasAssets
                    : [];
            });
            return updatedCanvases;
        }
        case ANNOTATIONS_ADD: {
            const canvasIdsToAnnotationIds = {};
            action.payload.annotations.forEach((annotation) => {
                if (!annotation.CanvasId || !state[annotation.CanvasId]) {
                    return;
                }

                if (!canvasIdsToAnnotationIds[annotation.CanvasId]) {
                    canvasIdsToAnnotationIds[annotation.CanvasId] = [];
                }

                canvasIdsToAnnotationIds[annotation.CanvasId].push(annotation.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToAnnotationIds).forEach((canvasId) => {
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.Annotations = [...canvasIdsToAnnotationIds[canvasId], ...editedCanvas.Annotations];
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case ANNOTATIONS_REMOVE: {
            const canvasIdsToAnnotationIds = {};
            action.payload.annotations.forEach((annotation) => {
                if (!annotation.CanvasId || !state[annotation.CanvasId]) {
                    return;
                }

                if (!canvasIdsToAnnotationIds[annotation.CanvasId]) {
                    canvasIdsToAnnotationIds[annotation.CanvasId] = [];
                }

                canvasIdsToAnnotationIds[annotation.CanvasId].push(annotation.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToAnnotationIds).forEach((canvasId) => {
                const annotationIdsToRemove = canvasIdsToAnnotationIds[canvasId];
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.Annotations = editedCanvas.Annotations.filter(
                    (annotationId) => !annotationIdsToRemove.includes(annotationId)
                );
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_ASSETS_ADD: {
            const canvasIdsToAssetIds = {};
            action.payload.canvasAssets.forEach((canvasAsset) => {
                if (!canvasAsset.CanvasId || !state[canvasAsset.CanvasId]) {
                    return;
                }

                if (!canvasIdsToAssetIds[canvasAsset.CanvasId]) {
                    canvasIdsToAssetIds[canvasAsset.CanvasId] = [];
                }

                canvasIdsToAssetIds[canvasAsset.CanvasId].push(canvasAsset.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToAssetIds).forEach((canvasId) => {
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasAssets = uniq([...canvasIdsToAssetIds[canvasId], ...editedCanvas.CanvasAssets]);
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_ASSETS_REMOVE: {
            const canvasIdsToAssetIds = {};
            action.payload.canvasAssets.forEach((canvasAsset) => {
                if (!canvasAsset.CanvasId || !state[canvasAsset.CanvasId]) {
                    return;
                }

                if (!canvasIdsToAssetIds[canvasAsset.CanvasId]) {
                    canvasIdsToAssetIds[canvasAsset.CanvasId] = [];
                }

                canvasIdsToAssetIds[canvasAsset.CanvasId].push(canvasAsset.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToAssetIds).forEach((canvasId) => {
                const canvasAssetsToRemove = canvasIdsToAssetIds[canvasId];
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasAssets = editedCanvas.CanvasAssets.filter(
                    (assetId) => !canvasAssetsToRemove.includes(assetId)
                );
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_REVISIONS_ADD: {
            const canvasIdsToRevisionIds = {};
            action.payload.canvasRevisions.forEach((canvasRevision) => {
                if (!canvasRevision.CanvasId || !state[canvasRevision.CanvasId]) {
                    return;
                }

                if (!canvasIdsToRevisionIds[canvasRevision.CanvasId]) {
                    canvasIdsToRevisionIds[canvasRevision.CanvasId] = [];
                }

                canvasIdsToRevisionIds[canvasRevision.CanvasId].push(canvasRevision.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToRevisionIds).forEach((canvasId) => {
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasRevisions = uniq([
                    ...canvasIdsToRevisionIds[canvasId],
                    ...editedCanvas.CanvasRevisions
                ]);
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_REVISIONS_REMOVE: {
            const canvasIdsToRevisionIds = {};
            action.payload.canvasRevisions.forEach((canvasRevision) => {
                if (!canvasRevision.CanvasId || !state[canvasRevision.CanvasId]) {
                    return;
                }

                if (!canvasIdsToRevisionIds[canvasRevision.CanvasId]) {
                    canvasIdsToRevisionIds[canvasRevision.CanvasId] = [];
                }

                canvasIdsToRevisionIds[canvasRevision.CanvasId].push(canvasRevision.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToRevisionIds).forEach((canvasId) => {
                const canvasRevisionsToRemove = canvasIdsToRevisionIds[canvasId];
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasRevisions = editedCanvas.CanvasRevisions.filter(
                    (canvasRevisionId) => !canvasRevisionsToRemove.includes(canvasRevisionId)
                );
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_REVIEWS_ADD: {
            const canvasIdsToReviewIds = {};
            action.payload.canvasReviews.forEach((canvasReview) => {
                if (!canvasReview.CanvasId || !state[canvasReview.CanvasId]) {
                    return;
                }

                if (!canvasIdsToReviewIds[canvasReview.CanvasId]) {
                    canvasIdsToReviewIds[canvasReview.CanvasId] = [];
                }

                canvasIdsToReviewIds[canvasReview.CanvasId].push(canvasReview.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToReviewIds).forEach((canvasId) => {
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasReviews = uniq([...canvasIdsToReviewIds[canvasId], ...editedCanvas.CanvasReviews]);
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_DEADLINE_REMINDERS_ADD: {
            const canvasIdsToDeadlineReminderIds = {};
            action.payload.canvasDeadlineReminders.forEach((canvasDeadlineReminder) => {
                if (!canvasDeadlineReminder.CanvasId || !state[canvasDeadlineReminder.CanvasId]) {
                    return;
                }

                if (!canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId]) {
                    canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId] = [];
                }

                canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId].push(canvasDeadlineReminder.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToDeadlineReminderIds).forEach((canvasId) => {
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasDeadlineReminders = uniq([
                    ...canvasIdsToDeadlineReminderIds[canvasId],
                    ...editedCanvas.CanvasDeadlineReminders
                ]);
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        case CANVAS_DEADLINE_REMINDERS_REMOVE: {
            const canvasIdsToDeadlineReminderIds = {};
            action.payload.canvasDeadlineReminders.forEach((canvasDeadlineReminder) => {
                if (!canvasDeadlineReminder.CanvasId || !state[canvasDeadlineReminder.CanvasId]) {
                    return;
                }

                if (!canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId]) {
                    canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId] = [];
                }

                canvasIdsToDeadlineReminderIds[canvasDeadlineReminder.CanvasId].push(canvasDeadlineReminder.id);
            });

            const editedCanvases = { ...state };
            Object.keys(canvasIdsToDeadlineReminderIds).forEach((canvasId) => {
                const canvasDeadlineRemindersToRemove = canvasIdsToDeadlineReminderIds[canvasId];
                const editedCanvas = { ...editedCanvases[canvasId] };
                editedCanvas.CanvasDeadlineReminders = editedCanvas.CanvasDeadlineReminders.filter(
                    (canvasDeadlineReminderId) => !canvasDeadlineRemindersToRemove.includes(canvasDeadlineReminderId)
                );
                editedCanvases[canvasId] = editedCanvas;
            });

            return editedCanvases;
        }
        default:
            return state;
    }
};

export default canvases;
