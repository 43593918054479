import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './store';
import { FEEDBACK_LINK_MARK_USER_AS_PRESENT } from './store/ui-feedback-link/types';

export default function makeStore(initialState) {
    const middleware = [thunkMiddleware];

    // Filtering out the action to mark users as present on the
    // canvas / feedback link page, since it shows up every 5 sec at minimum,
    // which clutters up the dev console.
    if (process.env.NODE_ENV === 'development') {
        middleware.push(
            createLogger({
                collapsed: true,
                predicate: (getState, action) => action.type !== FEEDBACK_LINK_MARK_USER_AS_PRESENT
            })
        );
    }

    return createStore(reducers, initialState, applyMiddleware(...middleware));
}
