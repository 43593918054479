import {
    REMOVE_TEAM_MEMBER_MODAL_HIDE,
    REMOVE_TEAM_MEMBER_MODAL_SHOW,
    SETTINGS_CHANGE_PASSWORD_MODAL_HIDE,
    SETTINGS_CHANGE_PASSWORD_MODAL_SHOW,
    SSO_SETTINGS_MODAL_HIDE,
    SSO_SETTINGS_MODAL_SHOW
} from './types';

const initialState = {
    changePasswordModalOpen: false,
    ssoSettingsModalOpen: false,
    teamMemberToModifyId: null
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_CHANGE_PASSWORD_MODAL_SHOW: {
            return { ...state, changePasswordModalOpen: true };
        }
        case SETTINGS_CHANGE_PASSWORD_MODAL_HIDE: {
            return { ...state, changePasswordModalOpen: false };
        }
        case SSO_SETTINGS_MODAL_SHOW: {
            return { ...state, ssoSettingsModalOpen: true };
        }
        case SSO_SETTINGS_MODAL_HIDE: {
            return { ...state, ssoSettingsModalOpen: false };
        }
        case REMOVE_TEAM_MEMBER_MODAL_SHOW: {
            return { ...state, teamMemberToModifyId: action.payload.userId };
        }
        case REMOVE_TEAM_MEMBER_MODAL_HIDE: {
            return { ...state, teamMemberToModifyId: null };
        }
        default:
            return state;
    }
};

export default settings;
