import {
    REMOVE_TEAM_MEMBER_MODAL_HIDE,
    REMOVE_TEAM_MEMBER_MODAL_SHOW,
    SETTINGS_CHANGE_PASSWORD_MODAL_HIDE,
    SETTINGS_CHANGE_PASSWORD_MODAL_SHOW,
    SSO_SETTINGS_MODAL_HIDE,
    SSO_SETTINGS_MODAL_SHOW
} from './types';

export function showSettingsChangePasswordModal() {
    return {
        type: SETTINGS_CHANGE_PASSWORD_MODAL_SHOW,
        payload: null
    };
}

export function hideSettingsChangePasswordModal() {
    return {
        type: SETTINGS_CHANGE_PASSWORD_MODAL_HIDE,
        payload: null
    };
}

export function showSSOSettingsModal() {
    return {
        type: SSO_SETTINGS_MODAL_SHOW,
        payload: null
    };
}

export function hideSSOSettingsModal() {
    return {
        type: SSO_SETTINGS_MODAL_HIDE,
        payload: null
    };
}

export function showRemoveTeamMemberModal(userId) {
    return {
        type: REMOVE_TEAM_MEMBER_MODAL_SHOW,
        payload: { userId }
    };
}

export function hideRemoveTeamMemberModal() {
    return {
        type: REMOVE_TEAM_MEMBER_MODAL_HIDE,
        payload: null
    };
}
