import { CANVASES_ADD } from '../canvases/types';
import { CANVAS_REVISIONS_ADD, CANVAS_REVISIONS_UPDATE, CANVAS_REVISIONS_REMOVE } from './types';

const canvasRevisions = (state = {}, action) => {
    switch (action.type) {
        case CANVAS_REVISIONS_ADD: {
            const addedCanvasRevisions = { ...state };
            action.payload.canvasRevisions.forEach((canvasRevision) => {
                addedCanvasRevisions[canvasRevision.id] = { ...canvasRevision };
            });
            return addedCanvasRevisions;
        }
        case CANVAS_REVISIONS_UPDATE: {
            const updatedCanvasRevisions = { ...state };
            action.payload.canvasRevisions.forEach((canvasRevision) => {
                updatedCanvasRevisions[canvasRevision.id] = {
                    ...updatedCanvasRevisions[canvasRevision.id],
                    ...canvasRevision
                };
            });
            return updatedCanvasRevisions;
        }
        case CANVAS_REVISIONS_REMOVE: {
            const removedCanvasRevisions = { ...state };
            action.payload.canvasRevisions.forEach((canvasRevision) => {
                delete removedCanvasRevisions[canvasRevision.id];
            });
            return removedCanvasRevisions;
        }
        case CANVASES_ADD: {
            const addedCanvasRevisions = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.CanvasRevisions) {
                    return;
                }

                canvas.CanvasRevisions.forEach((canvasRevision) => {
                    addedCanvasRevisions[canvasRevision.id] = { ...canvasRevision };
                });
            });
            return addedCanvasRevisions;
        }
        default:
            return state;
    }
};

export default canvasRevisions;
