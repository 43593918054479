import { ReactNode } from 'react';
import { tw } from 'twind';

type Padding = 'top' | 'bottom';
interface ContentProps {
    padding?: Padding[];
    bgColor?: 'gray' | 'white';
    children: ReactNode;
    className?: string;
}

const Content = ({ padding, bgColor, children, className }: ContentProps) => (
    <main
        className={tw(
            {
                'pt-10': padding?.includes('top'),
                'pb-12': padding?.includes('bottom'),
                'bg-white': bgColor === 'white',
                'bg-gray-100': bgColor === 'gray'
            },
            className
        )}
    >
        {children}
    </main>
);

export default Content;
