import { EVENTS_ADD, EVENTS_REMOVE } from './types';

const events = (state = {}, action) => {
    switch (action.type) {
        case EVENTS_ADD: {
            const addedEvents = { ...state };
            action.payload.events.forEach((event) => {
                addedEvents[event.id] = { ...event };
            });
            return addedEvents;
        }
        case EVENTS_REMOVE: {
            const removedEvents = { ...state };
            action.payload.events.forEach((event) => {
                delete removedEvents[event.id];
            });
            return removedEvents;
        }
        default:
            return state;
    }
};

export default events;
