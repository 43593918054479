import API from 'lib/api';
import { EVENTS_ADD, EVENTS_REMOVE } from './types';

export function addEvents(events) {
    return {
        type: EVENTS_ADD,
        payload: { events }
    };
}

export function removeEvents(events) {
    return {
        type: EVENTS_REMOVE,
        payload: { events }
    };
}

export function getUserEvents() {
    return (dispatch, getState) =>
        API.call('/users/me/events', 'get', null, getState().auth).then((events) => dispatch(addEvents(events)));
}

export function getCanvasEvents(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/events`, 'get', null, getState().auth).then((events) =>
            dispatch(addEvents(events))
        );
}

export function markEventsAsRead(events = []) {
    return (dispatch, getState) =>
        API.call(
            '/events/mark-as-read',
            'post',
            { eventIds: events.map((event) => event.id) },
            getState().auth
        ).then((events) => dispatch(removeEvents(events)));
}
