import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { CANVAS_REVIEWS_ADD } from './types';

export function addCanvasReviews(canvasReviews) {
    return {
        type: CANVAS_REVIEWS_ADD,
        payload: { canvasReviews }
    };
}

export function createCanvasReview(canvasId, status, comment) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        const canvas = canvases[canvasId];
        return API.call(`/canvases/${canvasId}/reviews`, 'post', { status, comment }, auth).then((canvasReview) => {
            analytics.addCanvasReview(canvas, canvasReview);
            dispatch(addCanvasReviews([canvasReview]));
            return canvasReview;
        });
    };
}
