/**
 * Given a filename, e.g. hello-world.txt or HEY_GUYS.JPG, 
 * will return a lower-cased file extension (e.g. txt or jpg).
 *
 * @export
 * @param {string} filename
 * @return {string} 
 */
export default function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
}
