import parse from 'url-parse';
import decodeProxyUrl from './decode-proxy-url';

const root = typeof window !== 'undefined' ? window : null;

/**
 * Helper function that parses a given URL.
 *
 * @param url
 * @param customContext
 * @returns {HTMLElement}
 */
export default function parseUrl(url, customContext = null) {
    let contextUrl;

    if (root) {
        contextUrl = root.document
            ? decodeProxyUrl(root.document.baseURI || root.location.href)
            : decodeProxyUrl(root.location.href);
    }

    const parsedUrl = customContext ? parse(url, customContext, false) : parse(url, contextUrl, false);

    // To maintain parity with HTMLAnchorElement
    parsedUrl.search = parsedUrl.query;

    return parsedUrl;
}
