import API from 'lib/api';
import flags from 'lib/flags';
import * as analytics from 'lib/analytics';
import { NotificationManager as notifications } from 'react-notifications';
import { highlightMentionsText as highlightMentions } from 'lib/highlight-mentions';
import { ANNOTATION_REPLIES_ADD, ANNOTATION_REPLIES_REMOVE, ANNOTATION_REPLIES_UPDATE } from './types';
import { selectFeedbackLinkAnnotation, openFeedbackLinkSidebar } from '../ui-feedback-link/actions';

export function addReplies(replies = []) {
    return {
        type: ANNOTATION_REPLIES_ADD,
        payload: { replies }
    };
}

export function updateReplies(replies = []) {
    return {
        type: ANNOTATION_REPLIES_UPDATE,
        payload: { replies }
    };
}

export function removeReplies(replies = []) {
    return {
        type: ANNOTATION_REPLIES_REMOVE,
        payload: { replies }
    };
}

export function createReply(reply) {
    return (dispatch, getState) => {
        const { auth, annotations, canvases } = getState();

        return API.call(`/annotations/${reply.AnnotationId}/replies`, 'post', reply, auth)
            .then((reply) => dispatch(addReplies([reply])))
            .then((result) => {
                const reply = result.payload.replies[0];
                const annotation = annotations[reply.AnnotationId];
                const canvas = canvases[annotation.CanvasId];
                analytics.createReply(reply, annotation, canvas);
                notifications.success(highlightMentions(reply.comment), 'You replied to a comment', 5000, () => {
                    dispatch(selectFeedbackLinkAnnotation(reply.AnnotationId));
                    dispatch(openFeedbackLinkSidebar());
                });
                return result;
            });
    };
}

export function modifyReply(reply) {
    return (dispatch, getState) => {
        const { auth, annotations, canvases } = getState();
        const commentChanged = Object.keys(reply).includes('comment');
        return API.call(`/replies/${reply.id}`, 'put', reply, auth).then((reply) => {
            const annotation = annotations[reply.AnnotationId];
            const canvas = canvases[annotation?.CanvasId];
            if (commentChanged) {
                analytics.editReply(reply, annotation, canvas);
            }
            return dispatch(updateReplies([reply]));
        });
    };
}

export function deleteReply(reply) {
    return (dispatch, getState) => {
        const { auth, annotations, canvases } = getState();
        return API.call(`/replies/${reply.id}`, 'delete', reply, auth).then((reply) => {
            const annotation = annotations[reply.AnnotationId];
            const canvas = canvases[annotation?.CanvasId];
            analytics.deleteReply(reply, annotation, canvas);
            return dispatch(removeReplies([reply]));
        });
    };
}

export function realtimeCreatedReply(reply) {
    return (dispatch, getState) => {
        dispatch(addReplies([reply]));

        const {
            canvases,
            annotations,
            ui,
            auth: { user }
        } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        const annotation = annotations[reply.AnnotationId];

        // Don't display notifications for private comments.
        if (
            (!annotation || annotation.isPrivate || reply.isPrivate) &&
            !flags.userHasPrivateCommentsEnabled(user, canvas)
        ) {
            return;
        }

        notifications.info(highlightMentions(reply.comment), `${reply.userName} replied to a comment`, 5000, () => {
            dispatch(selectFeedbackLinkAnnotation(reply.AnnotationId));
            dispatch(openFeedbackLinkSidebar());
        });
    };
}

export function realtimeUpdatedReply(reply) {
    return updateReplies([reply]);
}

export function realtimeDeletedReply(reply) {
    return removeReplies([reply]);
}
