export const BILLING_CARD_MODAL_HIDE = 'BILLING_CARD_MODAL_HIDE';
export const BILLING_CARD_MODAL_SHOW = 'BILLING_CARD_MODAL_SHOW';
export const BILLING_COUPON_MODAL_HIDE = 'BILLING_COUPON_MODAL_HIDE';
export const BILLING_COUPON_MODAL_SHOW = 'BILLING_COUPON_MODAL_SHOW';
export const BILLING_DATA_SET = 'BILLING_DATA_SET';
export const BILLING_EMAIL_MODAL_HIDE = 'BILLING_EMAIL_MODAL_HIDE';
export const BILLING_EMAIL_MODAL_SHOW = 'BILLING_EMAIL_MODAL_SHOW';
export const BILLING_INVOICE_SELECT = 'BILLING_INVOICE_SELECT';
export const BILLING_OFFBOARDING_SURVEY_MODAL_HIDE = 'BILLING_OFFBOARDING_SURVEY_MODAL_HIDE';
export const BILLING_OFFBOARDING_SURVEY_MODAL_SHOW = 'BILLING_OFFBOARDING_SURVEY_MODAL_SHOW';
export const BILLING_PLAN_FOR_SWITCH_SELECT = 'BILLING_PLAN_FOR_SWITCH_SELECT';
export const BILLING_PLAN_SET = 'BILLING_PLAN_SET';
export const BILLING_SWITCH_PLAN_MODAL_HIDE = 'BILLING_SWITCH_PLAN_MODAL_HIDE';
export const BILLING_SWITCH_PLAN_MODAL_SHOW = 'BILLING_SWITCH_PLAN_MODAL_SHOW';
export const BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE = 'BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE';
export const BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW = 'BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW';
export const BILLING_UPGRADE_TO_PAID_MODAL_HIDE = 'BILLING_UPGRADE_TO_PAID_MODAL_HIDE';
export const BILLING_UPGRADE_TO_PAID_MODAL_SHOW = 'BILLING_UPGRADE_TO_PAID_MODAL_SHOW';
