import { CANVAS_USERS_ADD, CANVAS_USERS_UPDATE } from './types';

const canvasUsers = (state = {}, action) => {
    switch (action.type) {
        case CANVAS_USERS_ADD: {
            const addedUsers = { ...state };
            action.payload.canvasUsers.forEach((canvasUser) => {
                addedUsers[canvasUser.id] = { ...canvasUser };
            });
            return addedUsers;
        }
        case CANVAS_USERS_UPDATE: {
            const updatedUsers = { ...state };
            action.payload.canvasUsers.forEach((canvasUser) => {
                updatedUsers[canvasUser.id] = { ...updatedUsers[canvasUser.id], ...canvasUser };
            });
            return updatedUsers;
        }
        default:
            return state;
    }
};

export default canvasUsers;
