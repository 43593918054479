import endsWith from 'lodash/endsWith';
import trimEnd from 'lodash/trimEnd';

/**
 * Normalizes urls from annotations (path === string)
 *
 * @export
 * @param {string} url
 * @returns {string}
 */
export default function normalizeUrl(url) {
    let cleanedUrl = url;

    // Strip off trailing slashes, such that /work === /work/
    if (url !== '/' && endsWith(url, '/')) {
        cleanedUrl = trimEnd(url, '/');
    }

    // Normalize path such that '' === '/'
    if (url === '') {
        cleanedUrl = '/';
    }

    return cleanedUrl;
}
