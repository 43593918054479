import { CANVASES_ADD } from '../canvases/types';
import { CANVAS_ASSETS_ADD, CANVAS_ASSETS_UPDATE, CANVAS_ASSETS_REMOVE } from './types';

const canvasAssets = (state = {}, action) => {
    switch (action.type) {
        case CANVAS_ASSETS_ADD: {
            const addedCanvasAssets = { ...state };
            action.payload.canvasAssets.forEach((canvasAsset) => {
                addedCanvasAssets[canvasAsset.id] = { ...canvasAsset };
            });
            return addedCanvasAssets;
        }
        case CANVASES_ADD: {
            const addedCanvasAssets = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.CanvasAssets) {
                    return;
                }

                canvas.CanvasAssets.forEach((canvasAsset) => {
                    addedCanvasAssets[canvasAsset.id] = { ...canvasAsset };
                });
            });
            return addedCanvasAssets;
        }
        case CANVAS_ASSETS_UPDATE: {
            const updatedCanvasAssets = { ...state };
            action.payload.canvasAssets.forEach((canvasAsset) => {
                updatedCanvasAssets[canvasAsset.id] = { ...updatedCanvasAssets[canvasAsset.id], ...canvasAsset };
            });
            return updatedCanvasAssets;
        }
        case CANVAS_ASSETS_REMOVE: {
            const removedCanvasAssets = { ...state };
            action.payload.canvasAssets.forEach((canvasAsset) => {
                delete removedCanvasAssets[canvasAsset.id];
            });
            return removedCanvasAssets;
        }
        default:
            return state;
    }
};

export default canvasAssets;
