import Promise from 'es6-promise';
import fetch from 'isomorphic-fetch';
import config from './config';
import errors from './errors';

Promise.polyfill();

class API {
    constructor() {
        this.sessionCookieName = 'pastel.sid.v1';
        this.impersonateUserIdCookieName = 'pastel.impersonate.uid.v1';
        this.loginStatusCookieName = 'pastel.user.logged-in.v1';
    }

    /**
     * Function to call the Pastel API
     * @param path
     * @param method
     * @param data
     * @param auth
     * @param res
     * @param useProxy
     * @returns {*}
     */
    async call(path, method = 'get', data = null, auth = null, res = null, useProxy = false) {
        let responseStatus = 200;

        const options = {
            method,
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        };

        // Pass along important cookies on the server-side, as these aren't passed along automatically like they are browser-side.
        if (auth && (auth.apiToken || auth.loginStatus) && typeof window === 'undefined') {
            const cookies = [];

            if (auth.apiToken) {
                cookies.push(`${this.sessionCookieName}=${auth.apiToken}`);
            }
            if (auth.impersonatedUserId) {
                cookies.push(`${this.impersonateUserIdCookieName}=${auth.impersonatedUserId}`);
            }

            if (auth.loginStatus) {
                cookies.push(`${this.loginStatusCookieName}=${auth.loginStatus}`);
            }

            if (cookies.length > 0) {
                options.headers.Cookie = cookies.join('; ');
            }
        }

        if (data) {
            options.body = JSON.stringify(data);
        }

        try {
            const response = await fetch(`${useProxy ? config.api.proxyUrl : config.api.baseUrl}/v1${path}`, options);
            // If we see a Set-Cookie header from the API, pass it along.
            // Note: this is so that we can pass cookies set by the API along to the user even in the server-rendered app.
            if (res && response.headers.has('set-cookie')) {
                res.set('Set-Cookie', response.headers.get('set-cookie'));
            }

            responseStatus = response.status;
            const jsonResponse = await response.json();
            // Was an error, so throw it.
            if (responseStatus >= 400) {
                throw new errors.AppError(jsonResponse.message, jsonResponse.error, responseStatus, jsonResponse.meta);
            }

            return jsonResponse;
        } catch (err) {
            if (!err.appError) {
                // Retry failed client-side requests that result in network errors _once_ via the API proxy
                if (config.api.proxyUrl && !useProxy && typeof window !== 'undefined') {
                    return this.call(path, method, data, auth, res, true);
                }

                err.name = 'NetworkError'; // eslint-disable-line no-param-reassign
                err.message = 'Network error.'; // eslint-disable-line no-param-reassign
            }

            throw err;
        }
    }

    /**
     * Function to upload a file to the Pastel API
     * @param path
     * @param method
     * @param files
     * @param apiToken
     * @param useProxy
     * @returns {*}
     */
    async upload(path, method = 'post', files = {}, apiToken = null, useProxy = false) {
        let responseStatus = 200;

        const data = new FormData();
        Object.keys(files).forEach((key) => {
            if (typeof files[key] === 'undefined' || files[key] === null) {
                return;
            }
            data.append(key, files[key]);
        });

        const options = {
            method,
            mode: 'cors',
            credentials: 'include',
            headers: {
                Accept: 'application/json'
            },
            body: data
        };

        if (apiToken && typeof window === 'undefined') {
            options.headers.Cookie = `${this.sessionCookieName}=${apiToken}`;
        }

        try {
            const response = await fetch(`${useProxy ? config.api.proxyUrl : config.api.baseUrl}/v1${path}`, options);
            responseStatus = response.status;
            const jsonResponse = await response.json();
            // Was an error, so throw it.
            if (responseStatus >= 400) {
                throw new errors.AppError(jsonResponse.message, jsonResponse.error, responseStatus, jsonResponse.meta);
            }

            return jsonResponse;
        } catch (err) {
            if (!err.appError) {
                // Retry failed client-side requests that result in network errors _once_ via the API proxy
                if (config.api.proxyUrl && !useProxy && typeof window !== 'undefined') {
                    return this.upload(path, method, files, apiToken, useProxy);
                }

                err.name = 'NetworkError'; // eslint-disable-line no-param-reassign
                err.message = 'Network error.'; // eslint-disable-line no-param-reassign
            }

            throw err;
        }
    }

    /**
     * Creates a new user & logs them in
     * @param email
     * @param name
     * @param password
     * @param coupon
     * @param plan
     * @param teamName
     * @param inviteCode
     * @param apiToken
     * @param initialVisitData
     */
    signup(
        {
            email,
            name,
            password,
            teamName = null,
            coupon = null,
            plan = null,
            inviteCode = null,
            initialVisitData = null
        },
        apiToken = null
    ) {
        return this.call(
            '/users',
            'post',
            {
                email,
                name,
                password,
                teamName,
                coupon,
                plan,
                inviteCode,
                initialVisitData
            },
            apiToken
        );
    }

    /**
     * Logs a user in
     * @param email
     * @param password
     * @param apiToken
     */
    login({ email, password }, apiToken = null) {
        return this.call('/sessions', 'post', { email, password }, apiToken);
    }

    /**
     * Logs a user out
     */
    logout(apiToken = null) {
        return this.call('/sessions', 'delete', null, apiToken);
    }

    /**
     * Logs a guest user in
     * @param email
     * @param name
     * @param apiToken
     * @returns {*}
     */
    loginGuest({ email, name }, apiToken = null) {
        return this.call('/guests', 'post', { email, name }, apiToken);
    }
}

const api = new API();
export default api;
