import { DASHBOARD_CANVASES_SET, DASHBOARD_CANVASES_ADD, DASHBOARD_CANVASES_REMOVE } from './types';

const initialState = {
    ids: [],
    loaded: false
};

const dashboards = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_CANVASES_SET:
            return { ids: action.payload.canvases.map((canvas) => canvas.id), loaded: true };
        case DASHBOARD_CANVASES_ADD: {
            const newIds = action.payload.canvases.map((canvas) => canvas.id).filter((id) => !state.ids.includes(id));
            const ids = action.payload.location === 'start' ? [...newIds, ...state.ids] : [...state.ids, ...newIds];
            return { ids, loaded: true };
        }
        case DASHBOARD_CANVASES_REMOVE: {
            const canvasIdsToRemove = action.payload.canvases.map((canvas) => canvas.id);
            const remainingIds = state.ids.filter((id) => !canvasIdsToRemove.includes(id));
            return { ids: remainingIds, loaded: true };
        }
        default:
            return state;
    }
};

export default dashboards;
