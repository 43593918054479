import { CANVASES_ADD } from '../canvases/types';
import { CANVAS_DEADLINE_REMINDERS_ADD, CANVAS_DEADLINE_REMINDERS_REMOVE } from './types';

const canvasDeadlineReminders = (state = {}, action) => {
    switch (action.type) {
        case CANVAS_DEADLINE_REMINDERS_ADD: {
            const addedCanvasDeadlineReminders = { ...state };
            action.payload.canvasDeadlineReminders.forEach((canvasDeadlineReminder) => {
                addedCanvasDeadlineReminders[canvasDeadlineReminder.id] = { ...canvasDeadlineReminder };
            });
            return addedCanvasDeadlineReminders;
        }
        case CANVAS_DEADLINE_REMINDERS_REMOVE: {
            const removedCanvasDeadlineReminders = { ...state };
            action.payload.canvasDeadlineReminders.forEach((canvasDeadlineReminder) => {
                delete removedCanvasDeadlineReminders[canvasDeadlineReminder.id];
            });
            return removedCanvasDeadlineReminders;
        }
        case CANVASES_ADD: {
            const addedCanvasDeadlineReminders = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.CanvasDeadlineReminders) {
                    return;
                }
                canvas.CanvasDeadlineReminders.forEach((canvasDeadlineReminder) => {
                    addedCanvasDeadlineReminders[canvasDeadlineReminder.id] = { ...canvasDeadlineReminder };
                });
            });
            return addedCanvasDeadlineReminders;
        }
        default:
            return state;
    }
};

export default canvasDeadlineReminders;
