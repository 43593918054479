import {
    AUTH_USER_LOGIN,
    AUTH_USER_LOGOUT,
    AUTH_USER_UPDATE,
    AUTH_API_TOKEN_SET,
    AUTH_API_IMPERSONATE_USER_ID_SET,
    AUTH_API_LOGIN_STATUS_SET
} from './types';

const initialState = {
    user: null,
    loaded: false,
    apiToken: null,
    impersonatedUserId: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_USER_LOGIN:
            return { ...state, user: action.payload, loaded: true };
        case AUTH_USER_LOGOUT:
            return { ...state, user: null, loaded: true };
        case AUTH_USER_UPDATE:
            return { ...state, user: { ...state.user, ...action.payload.user } };
        case AUTH_API_TOKEN_SET:
            return { ...state, apiToken: action.payload.apiToken };
        case AUTH_API_IMPERSONATE_USER_ID_SET:
            return { ...state, impersonatedUserId: action.payload.userId };
        case AUTH_API_LOGIN_STATUS_SET:
            return { ...state, loginStatus: action.payload.loginStatus };
        default:
            return state;
    }
};

export default auth;
