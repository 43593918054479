import { TEAM_INVITES_ADD, TEAM_INVITES_REMOVE } from './types';

const teamInvites = (state = {}, action) => {
    switch (action.type) {
        case TEAM_INVITES_ADD: {
            const invites = { ...state };
            action.payload.invites.forEach((invite) => {
                invites[invite.id] = { ...invite };
            });
            return invites;
        }
        case TEAM_INVITES_REMOVE: {
            const invites = { ...state };
            action.payload.invites.forEach((invite) => {
                delete invites[invite.id];
            });
            return invites;
        }
        default:
            return state;
    }
};

export default teamInvites;
