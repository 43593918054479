import { CANVASES_ADD, CANVASES_REMOVE, CANVASES_UPDATE } from 'store/canvases/types';
import { FOLDERS_ADD, FOLDERS_REMOVE, FOLDERS_UPDATE } from './types';

const folders = (state = {}, action) => {
    switch (action.type) {
        case FOLDERS_ADD: {
            const addedFolders = { ...state };
            action.payload.folders.forEach((folder) => {
                addedFolders[folder.id] = { ...folder };
            });
            return addedFolders;
        }
        case FOLDERS_REMOVE: {
            const removedFolders = { ...state };
            action.payload.folders.forEach((folder) => {
                delete removedFolders[folder.id];
            });
            return removedFolders;
        }
        case FOLDERS_UPDATE: {
            const updatedFolders = { ...state };
            action.payload.folders.forEach((folder) => {
                updatedFolders[folder.id] = { ...updatedFolders[folder.id], ...folder };
            });
            return updatedFolders;
        }
        case CANVASES_ADD: {
            if (action.payload.isInitialLoad) {
                return state;
            }
            const updatedFolders = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.FolderId) {
                    return;
                }

                if (!updatedFolders[canvas.FolderId]) {
                    return;
                }

                updatedFolders[canvas.FolderId] = {
                    ...updatedFolders[canvas.FolderId],
                    canvasesCount: updatedFolders[canvas.FolderId].canvasesCount + 1
                };
            });
            return updatedFolders;
        }
        case CANVASES_REMOVE: {
            const updatedFolders = { ...state };
            action.payload.canvases.forEach((canvas) => {
                if (!canvas.FolderId) {
                    return;
                }

                if (!updatedFolders[canvas.FolderId]) {
                    return;
                }

                updatedFolders[canvas.FolderId] = {
                    ...updatedFolders[canvas.FolderId],
                    canvasesCount: updatedFolders[canvas.FolderId].canvasesCount - 1
                };
            });
            return updatedFolders;
        }
        case CANVASES_UPDATE: {
            const updatedFolders = { ...state };
            action.payload.canvases.forEach((canvas) => {
                const nextFolderId = canvas.FolderId;
                const prevFolderId = action.payload.prevCanvases[canvas.id]?.FolderId;
                const nextArchiveDate = canvas.archiveDate;
                const prevArchiveDate = action.payload.prevCanvases[canvas.id]?.archiveDate;

                if (nextFolderId && updatedFolders[nextFolderId]) {
                    updatedFolders[nextFolderId] = {
                        ...updatedFolders[nextFolderId],
                        canvasesCount: updatedFolders[nextFolderId].canvasesCount + 1
                    };
                }

                if (prevFolderId && updatedFolders[prevFolderId]) {
                    updatedFolders[prevFolderId] = {
                        ...updatedFolders[prevFolderId],
                        canvasesCount: updatedFolders[prevFolderId].canvasesCount - 1
                    };
                }

                // Archiving canvas from a folder
                if (nextArchiveDate && !prevArchiveDate && prevFolderId && updatedFolders[prevFolderId]) {
                    updatedFolders[prevFolderId] = {
                        ...updatedFolders[prevFolderId],
                        canvasesCount: updatedFolders[prevFolderId].canvasesCount - 1
                    };
                }

                // Unarchiving canvas back into a folder
                if (prevArchiveDate && !nextArchiveDate && prevFolderId && updatedFolders[prevFolderId]) {
                    updatedFolders[prevFolderId] = {
                        ...updatedFolders[prevFolderId],
                        canvasesCount: updatedFolders[prevFolderId].canvasesCount + 1
                    };
                }
            });
            return updatedFolders;
        }
        default:
            return state;
    }
};

export default folders;
