import React from 'react';
import styles from './info-toast.scss';

const InfoToast = ({ toast }) => (
    <div className="toast">
        <style jsx>{styles}</style>
        {toast.message}
        {toast.action && <button onClick={toast.action.onClick}>{toast.action.name}</button>}
    </div>
);

export default InfoToast;
