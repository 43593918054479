import router from 'next/router';

/**
 * Helper class for universal redirects (i.e. ones that work on both client and server)
 */
class UniversalRedirect {
    constructor() {
        this.res = null;
    }

    initialize(res = null) {
        this.res = res;
    }

    push(url) {
        if (!this.res) {
            router.push(url);
            return;
        }

        this.res.writeHead(307, {
            Location: url
        });
        this.res.end();
    }
}

const redirect = new UniversalRedirect();
export default redirect;
