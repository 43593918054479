import { SIGNUP_INVITE_SET } from './types';

const initialState = { inviteId: null };

const signup = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP_INVITE_SET:
            return { ...state, inviteId: action.payload.inviteId };
        default:
            return state;
    }
};

export default signup;
