import parse from 'url-parse';
import startsWith from 'lodash/startsWith';

const URL_PREFIX = '/_url/';
const DOMAINS_PREFIX = '/_domains/';

/**
 * Decodes original URL from an encoded dynamic proxy URL.
 *
 * @param url
 * @returns {*}
 */
export default function decodeProxyUrl(url) {
    if (!url) {
        return url;
    }

    // Note: we use this parse function directly rather than our helper parse-url function
    // that wraps this and auto-completes the context URL.
    // The reason being, we can make some assumptions about what the host (pastelproxy.com, pastelproxy.test) will be,
    // and fail out if that host is not there.
    // Additionally, the parse-url function makes use of decodeProxyUrl.
    const indexOfUrl = url.indexOf(URL_PREFIX);
    const containsDomainsPrefix = url.includes(DOMAINS_PREFIX);

    // Detect incorrectly formatted URL
    if (!containsDomainsPrefix || indexOfUrl < 0) {
        return url;
    }

    const decodedUrl = url.slice(indexOfUrl + URL_PREFIX.length);
    const parsedDecodedUrl = parse(decodedUrl, undefined, false);

    if (!parsedDecodedUrl) {
        return decodedUrl;
    }

    // We have a relative URL on our hands,
    // so we should return a relative URL
    if (startsWith(url, '/')) {
        return `${parsedDecodedUrl.pathname}${parsedDecodedUrl.search || ''}${parsedDecodedUrl.hash || ''}`;
    }

    return decodedUrl;
}
