import { Component } from 'react';
import Cookies from 'universal-cookie';
import mixpanel from 'mixpanel-browser';
import redirect from '../lib/universal-redirect';
import API from '../lib/api';
import { storeInitialVisitData } from '../lib/initial-visit-tracking';
import * as analytics from '../lib/analytics';
import config from '../lib/config';
import { getLoggedInUser, setAPIToken, setImpersonatedUserId, setLoginStatus } from '../store/actions';

/**
 * Note: All top-level pages should inherit from this class.
 * It handles initializing the authentication state.
 * TODO: Should refactor most of this into _app.js
 */
export default class Page extends Component {
    /**
     * Initializes both the API library and the user's login state within Redux.
     *
     * @param req
     * @param res
     * @param store
     * @returns {Promise<U>|*|Promise.<TResult>|Thenable<U>}
     */
    static getInitialProps({ req = null, res = null, store }) {
        const state = store.getState();

        redirect.initialize(res);

        // No need to initialize the user state with a network call if that's already happened.
        if (state.auth.loaded) {
            return Promise.resolve();
        }

        return Promise.resolve()
            .then(() => {
                if (!req || !req.headers.cookie) {
                    return {};
                }

                const cookies = new Cookies(req.headers.cookie);
                const apiToken = cookies.get(API.sessionCookieName);
                const loginStatus = cookies.get(API.loginStatusCookieName);
                store.dispatch(setImpersonatedUserId(cookies.get(API.impersonateUserIdCookieName)));
                store.dispatch(setAPIToken(apiToken));
                store.dispatch(setLoginStatus(loginStatus));
                return { apiToken, loginStatus };
            })
            .then(({ apiToken, loginStatus }) => {
                if (!apiToken && !loginStatus) {
                    return null;
                }

                // Note: res is being passed in here for session cookie management reasons.
                return store.dispatch(getLoggedInUser(res));
            })
            .then(() => null);
    }

    constructor(props) {
        super(props);

        // Used to indicate that the user must be logged-in to view this page.
        this.mustBeLoggedIn = false;

        // Used to indicate that the user must be logged-out to view this page.
        // Note: only one of these two variables should be true at once.
        this.mustBeLoggedOut = false;

        // Send logged out user to signup page instead of login
        this.sendLoggedOutUserToSignupPage = false;
    }

    UNSAFE_componentWillMount() {
        const { user } = this.props;

        if (this.mustBeLoggedIn && (!user || user.isGuest)) {
            if (this.sendLoggedOutUserToSignupPage) {
                redirect.push('/signup');
            } else {
                redirect.push('/login');
            }
        } else if (this.mustBeLoggedOut && user && !user.isGuest) {
            redirect.push('/dashboard');
        }

        // Initialize Mixpanel on client-side
        if (typeof window !== 'undefined') {
            mixpanel.init(config.mixpanel.projectId);
            window.mixpanel = mixpanel;
        }

        analytics.identify(user);
        analytics.viewPage(user);
        analytics.intercomIdentify(user);
        storeInitialVisitData(user);
    }
}
