/**
 * Regex for matching @mentions in Annotations and AnnotationReplies
 * Works for languages other than English
 *
 * mentionsRegexFull Example:
 * String: "Hey @[Hermione Granger](user:4), look at this!"
 * Match: @[Hermione Granger](user:4)
 * Capture Group 1: Hermione Granger
 * Capture Group 2: 4
 */

/**  TODO: Combine both Regexes
 * highlightMentionsReact works with mentionsRegex but not mentionsRegexFull
 * due to reactStringReplace (https://www.npmjs.com/package/react-string-replace)
 */

export const mentionsRegexFull = /@\[([^[\]]+)]\([\dA-Za-z]+:([\dA-Za-z]+)\)/g;
export const mentionsRegex = /@\[([^[\]]+)]\([\d:A-Za-z]+\)/g;
