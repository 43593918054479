import API from 'lib/api';
import { USERS_ADD, USERS_REMOVE, USERS_UPDATE } from './types';
import { setLoggedInUser } from '../auth/actions';

export function addUsers(users = []) {
    return {
        type: USERS_ADD,
        payload: { users }
    };
}

export function updateUsers(users = []) {
    return {
        type: USERS_UPDATE,
        payload: { users }
    };
}

export function removeUsers(users = []) {
    return {
        type: USERS_REMOVE,
        payload: { users }
    };
}

export function fetchTeamUsers(teamId) {
    return (dispatch, getState) =>
        API.call(`/teams/${teamId}/users`, 'get', null, getState().auth).then((users) => dispatch(addUsers(users)));
}

export function fetchCanvasUsers(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/users`, 'get', null, getState().auth).then((users) =>
            dispatch(addUsers(users))
        );
}

export function addCanvasUser(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/users`, 'post', { id: canvasId }, getState().auth).then((user) =>
            dispatch(addUsers([user]))
        );
}

export function setTeamAdmin(userId, teamId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${teamId}/admins`, 'post', { userId }, auth.apiToken).then((user) => {
            if (auth.user && auth.user.id === user.id) {
                dispatch(setLoggedInUser(user));
            }

            return dispatch(updateUsers([user]));
        });
    };
}

export function removeTeamAdmin(userId, teamId) {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call(`/teams/${teamId}/admins`, 'delete', { userId }, auth.apiToken).then((user) => {
            if (auth.user && auth.user.id === user.id) {
                dispatch(setLoggedInUser(user));
            }

            return dispatch(updateUsers([user]));
        });
    };
}

export function removeUserFromTeam(teamId, userId) {
    return (dispatch, getState) =>
        API.call(`/teams/${teamId}/users/${userId}`, 'delete', null, getState().auth).then((user) => {
            dispatch(removeUsers([{ id: userId }]));
            dispatch(setLoggedInUser(user));
        });
}
