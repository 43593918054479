import { EXTERNAL_ACCOUNTS_ADD, EXTERNAL_ACCOUNTS_REMOVE } from './types';

const externalAccounts = (state = {}, action) => {
    switch (action.type) {
        case EXTERNAL_ACCOUNTS_ADD: {
            const addedAccounts = { ...state };
            action.payload.accounts.forEach((account) => {
                addedAccounts[account.type] = { ...account };
            });
            return addedAccounts;
        }
        case EXTERNAL_ACCOUNTS_REMOVE: {
            const removedAccounts = { ...state };
            action.payload.accounts.forEach((account) => {
                delete removedAccounts[account.type];
            });
            return removedAccounts;
        }
        default:
            return state;
    }
};

export default externalAccounts;
