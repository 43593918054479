import config from './config';

/**
 * Register signup with affiliate software (GrowSumo/PartnerStack)
 * @param {*} userFix
 * @memberof Affiliate
 */
export function registerSignup(user) {
    const { growsumo } = window;

    if (growsumo && growsumo.createSignup && config.env !== 'development') {
        growsumo.data.name = user.name;
        growsumo.data.email = user.email;
        growsumo.data.customer_key = user.Customer.customerId; // From Stripe
        growsumo.createSignup();
    }
}
