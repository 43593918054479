import { LABELS_ADD, LABELS_UPDATE, LABELS_REMOVE } from './types';

const labels = (state = {}, action) => {
    switch (action.type) {
        case LABELS_ADD: {
            const addedLabels = { ...state };
            action.payload.labels.forEach((label) => {
                addedLabels[label.id] = { ...label };
            });
            return addedLabels;
        }
        case LABELS_UPDATE: {
            const updatedLabels = { ...state };
            action.payload.labels.forEach((label) => {
                updatedLabels[label.id] = { ...updatedLabels[label.id], ...label };
            });
            return updatedLabels;
        }
        case LABELS_REMOVE: {
            const removedLabels = { ...state };
            action.payload.labels.forEach((label) => {
                delete removedLabels[label.id];
            });
            return removedLabels;
        }
        default:
            return state;
    }
};

export default labels;
