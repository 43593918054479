import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { CANVAS_DEADLINE_REMINDERS_ADD, CANVAS_DEADLINE_REMINDERS_REMOVE } from './types';

export function addCanvasDeadlineReminders(canvasDeadlineReminders) {
    return {
        type: CANVAS_DEADLINE_REMINDERS_ADD,
        payload: { canvasDeadlineReminders }
    };
}

export function removeCanvasDeadlineReminders(canvasDeadlineReminders) {
    return {
        type: CANVAS_DEADLINE_REMINDERS_REMOVE,
        payload: { canvasDeadlineReminders }
    };
}

export function createCanvasDeadlineReminder(canvasId, userIds, emails, reminderDates) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        const canvas = canvases[canvasId];
        return API.call(
            `/canvases/${canvasId}/deadline-reminders`,
            'post',
            { userIds, emails, reminderDates },
            auth
        ).then((canvasDeadlineReminder) => {
            analytics.setFeedbackDeadlineReminder(canvas, canvasDeadlineReminder);
            dispatch(addCanvasDeadlineReminders(canvasDeadlineReminder));
            return canvasDeadlineReminder;
        });
    };
}

export function deleteCanvasDeadlineReminder(canvasDeadlineReminder) {
    return (dispatch, getState) =>
        API.call(`/deadline-reminders/${canvasDeadlineReminder.id}`, 'delete', null, getState().auth).then(
            (reminder) => {
                dispatch(removeCanvasDeadlineReminders([reminder]));
                return canvasDeadlineReminder;
            }
        );
}

export function fetchCanvasDeadlineReminders(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/deadline-reminders`, 'get', null, getState().auth).then(
            (canvasDeadlineReminder) => {
                dispatch(addCanvasDeadlineReminders(canvasDeadlineReminder));
                return canvasDeadlineReminder;
            }
        );
}
