import { USERS_ADD, USERS_REMOVE, USERS_UPDATE } from './types';

const users = (state = {}, action) => {
    switch (action.type) {
        case USERS_ADD: {
            const users = { ...state };
            action.payload.users.forEach((user) => {
                users[user.id] = { ...user };
            });
            return users;
        }
        case USERS_REMOVE: {
            const users = { ...state };
            action.payload.users.forEach((user) => {
                delete users[user.id];
            });
            return users;
        }
        case USERS_UPDATE: {
            const users = { ...state };
            action.payload.users.forEach((user) => {
                const oldUser = users[user.id] || {};
                users[user.id] = { ...oldUser, ...user };
            });
            return users;
        }
        default:
            return state;
    }
};

export default users;
