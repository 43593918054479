import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { EXTERNAL_ACCOUNTS_ADD, EXTERNAL_ACCOUNTS_REMOVE } from './types';

export function addExternalAccounts(accounts = []) {
    return {
        type: EXTERNAL_ACCOUNTS_ADD,
        payload: { accounts }
    };
}

export function removeExternalAccounts(accounts = []) {
    return {
        type: EXTERNAL_ACCOUNTS_REMOVE,
        payload: { accounts }
    };
}

export function getExternalAccounts() {
    return (dispatch, getState) =>
        API.call('/users/me/accounts', 'get', null, getState().auth).then((accounts) =>
            dispatch(addExternalAccounts(accounts))
        );
}

export function disconnectExternalAccount(account) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(`/users/me/accounts/${account.type}`, 'delete', null, auth).then(() => {
            analytics.disconnectExport(canvas, account.type);
            dispatch(removeExternalAccounts([account]));
        });
    };
}

export function connectTrelloAccount({ token }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call('/users/me/accounts/trello', 'post', { token }, auth).then((account) => {
            analytics.setupExport(canvas, account.type);
            dispatch(addExternalAccounts([account]));
        });
    };
}

export function connectMondayAccount({ token }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call('/users/me/accounts/monday', 'post', { token }, auth).then((account) => {
            analytics.setupExport(canvas, account.type);
            dispatch(addExternalAccounts([account]));
        });
    };
}

export function connectJiraAccount({ username, url, token }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call('/users/me/accounts/jira', 'post', { username, url, token }, auth).then((account) => {
            analytics.setupExport(canvas, account.type);
            dispatch(addExternalAccounts([account]));
        });
    };
}

export function connectWebhookAccount({ url }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call('/users/me/accounts/webhook', 'post', { url }, auth).then((account) => {
            analytics.setupExport(canvas, account.type);
            dispatch(addExternalAccounts([account]));
        });
    };
}
