/* eslint-disable no-case-declarations */
import { ANNOTATION_REPLIES_ADD, ANNOTATION_REPLIES_REMOVE } from '../annotation-replies/types';
import { FILE_ATTACHMENTS_REMOVE } from '../file-attachments/types';
import { ANNOTATIONS_ADD, ANNOTATIONS_UPDATE, ANNOTATIONS_REMOVE } from './types';

const annotations = (state = {}, action) => {
    switch (action.type) {
        case ANNOTATIONS_ADD:
            const addedAnnotations = { ...state };
            action.payload.annotations.forEach((annotation) => {
                const addedAnnotation = { ...annotation };
                addedAnnotation.FileAttachments = annotation.FileAttachments
                    ? annotation.FileAttachments.map((attachment) => attachment.id)
                    : [];
                addedAnnotation.numFileAttachments =
                    addedAnnotation.FileAttachments.length +
                    (addedAnnotation.AnnotationReplies ? addedAnnotation.AnnotationReplies : []).reduce(
                        (total, reply) => total + (reply.FileAttachments ? reply.FileAttachments.length : 0),
                        0
                    );
                addedAnnotation.AnnotationReplies = annotation.AnnotationReplies
                    ? annotation.AnnotationReplies.map((reply) => reply.id)
                    : [];
                addedAnnotation.Labels = annotation.Labels ? annotation.Labels.map((label) => label.id) : [];
                addedAnnotations[annotation.id] = addedAnnotation;
            });
            return addedAnnotations;
        case ANNOTATIONS_UPDATE:
            const updatedAnnotations = { ...state };
            action.payload.annotations.forEach((annotation) => {
                const previousAnnotation = updatedAnnotations[annotation.id] || {};

                // Can run into race conditions with resolving & screenshot generation,
                // so we do this to handle that situation.
                if (!previousAnnotation.screenshotUrl && annotation.screenshotUrl) {
                    updatedAnnotations[annotation.id] = {
                        ...previousAnnotation,
                        screenshotUrl: annotation.screenshotUrl,
                        afterScreenshotUrl: annotation.afterScreenshotUrl
                    };
                } else {
                    updatedAnnotations[annotation.id] = {
                        ...previousAnnotation,
                        ...annotation,
                        Labels: annotation?.Labels
                            ? annotation.Labels.map((label) => (typeof label === 'object' ? label.id : label))
                            : updatedAnnotations[annotation.id].Labels
                    };
                }
            });
            return updatedAnnotations;
        case ANNOTATIONS_REMOVE:
            const removedAnnotations = { ...state };
            action.payload.annotations.forEach((annotation) => {
                delete removedAnnotations[annotation.id];
            });
            return removedAnnotations;
        case ANNOTATION_REPLIES_ADD:
            const annotations = { ...state };
            action.payload.replies.forEach((reply) => {
                if (!reply.AnnotationId) {
                    return;
                }

                const modifiedAnnotation = { ...annotations[reply.AnnotationId] };
                modifiedAnnotation.AnnotationReplies.push(reply.id);
                modifiedAnnotation.numFileAttachments += reply.FileAttachments ? reply.FileAttachments.length : 0;
                annotations[reply.AnnotationId] = modifiedAnnotation;
            });
            return annotations;
        case ANNOTATION_REPLIES_REMOVE: {
            const annotations = { ...state };
            action.payload.replies.forEach((reply) => {
                if (!reply.AnnotationId) {
                    return;
                }

                const annotation = { ...annotations[reply.AnnotationId] };
                annotation.AnnotationReplies = annotation.AnnotationReplies.filter((replyId) => replyId !== reply.id);
                annotation.numFileAttachments -= reply.FileAttachments ? reply.FileAttachments.length : 0;

                annotations[reply.AnnotationId] = annotation;
            });
            return annotations;
        }
        case FILE_ATTACHMENTS_REMOVE: {
            const annotations = { ...state };

            action.payload.fileAttachments.forEach((fileAttachment) => {
                if (!fileAttachment.AnnotationId) {
                    return;
                }

                const modifiedAnnotation = { ...annotations[fileAttachment.AnnotationId] };
                modifiedAnnotation.numFileAttachments -= 1;
                annotations[fileAttachment.AnnotationId] = modifiedAnnotation;
            });

            return annotations;
        }
        default:
            return state;
    }
};

export default annotations;
