import parseUrl from 'url-parse';
import Cookies from 'universal-cookie';

/**
 * Helper function to parse a URL and then delete unnecessary values for initial visit data storage.
 *
 * @param {*} [parsedUrl={}]
 * @return {*}
 */
function parseUrlAndRemoveUnusedValues(url) {
    const parsedUrl = parseUrl(url, null, true);
    delete parsedUrl.auth;
    delete parsedUrl.hash;
    delete parsedUrl.host;
    delete parsedUrl.origin;
    delete parsedUrl.password;
    delete parsedUrl.port;
    delete parsedUrl.slashes;
    delete parsedUrl.protocol;
    delete parsedUrl.username;
    return parsedUrl;
}

/**
 * Store initial url parameters and referer, so that when the user signs up,
 * we can stash this info in the DB. Useful for figuring out attribution of sign ups, particularly for our ads.
 * @param {*} user
 */
export function storeInitialVisitData(user) {
    if (typeof window === 'undefined' || user) {
        return;
    }

    const cookies = new Cookies();

    // Only store initial visit data once.
    if (cookies.get('pastel.initialVisitData')) {
        return;
    }

    const parsedInitialUrl = window.location.href ? parseUrlAndRemoveUnusedValues(window.location.href) : {};
    const parsedReferrer = document.referrer ? parseUrlAndRemoveUnusedValues(document.referrer) : {};

    const initialVisitData = {
        referrer: parsedReferrer,
        initialUrl: parsedInitialUrl
    };

    // Store this for 30 days
    cookies.set('pastel.initialVisitData', initialVisitData, { maxAge: 60 * 60 * 24 * 30 });
}

/**
 * Gets stored initial visit data.
 */
export function getInitialVisitData() {
    if (typeof window === 'undefined') {
        return null;
    }

    const cookies = new Cookies();
    return cookies.get('pastel.initialVisitData');
}
