import { DASHBOARD_FOLDERS_SET, DASHBOARD_FOLDERS_ADD, DASHBOARD_FOLDERS_REMOVE } from './types';

const initialState = {
    ids: [],
    loaded: false
};

const dashboardFolders = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FOLDERS_SET:
            return { ids: action.payload.folders.map((folder) => folder.id), loaded: true };
        case DASHBOARD_FOLDERS_ADD: {
            const newFolderIds = action.payload.folders
                .map((folder) => folder.id)
                .filter((id) => !state.ids.includes(id));
            return { ids: [...state.ids, ...newFolderIds], loaded: true };
        }
        case DASHBOARD_FOLDERS_REMOVE: {
            const folderIdsToRemove = action.payload.folders.map((folder) => folder.id);
            const remainingFolderIds = state.ids.filter((id) => !folderIdsToRemove.includes(id));
            return { ids: remainingFolderIds, loaded: true };
        }
        default:
            return state;
    }
};

export default dashboardFolders;
