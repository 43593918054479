import { ANNOTATIONS_ADD } from '../annotations/types';
import { ANNOTATION_REPLIES_ADD } from '../annotation-replies/types';
import { FILE_ATTACHMENTS_ADD, FILE_ATTACHMENTS_REMOVE } from './types';

const fileAttachments = (state = {}, action) => {
    switch (action.type) {
        case FILE_ATTACHMENTS_ADD: {
            const addedFileAttachments = { ...state };
            action.payload.fileAttachments.forEach((fileAttachment) => {
                addedFileAttachments[fileAttachment.id] = { ...fileAttachment };
            });
            return addedFileAttachments;
        }
        case FILE_ATTACHMENTS_REMOVE: {
            const removedFileAttachments = { ...state };
            action.payload.fileAttachments.forEach((fileAttachment) => {
                delete removedFileAttachments[fileAttachment.id];
            });
            return removedFileAttachments;
        }
        case ANNOTATIONS_ADD: {
            const fileAttachments = { ...state };
            const newFileAttachments = [];

            action.payload.annotations.forEach((annotation) => {
                if (annotation?.FileAttachments) {
                    annotation.FileAttachments.forEach((attachment) => newFileAttachments.push(attachment));
                }

                if (annotation.AnnotationReplies) {
                    annotation.AnnotationReplies.forEach((reply) => {
                        reply.FileAttachments.forEach((attachment) => newFileAttachments.push(attachment));
                    });
                }
            });

            newFileAttachments.forEach((fileAttachment) => {
                fileAttachments[fileAttachment.id] = { ...fileAttachment };
            });

            return fileAttachments;
        }
        case ANNOTATION_REPLIES_ADD: {
            const fileAttachments = { ...state };
            const newFileAttachments = [];

            action.payload.replies.forEach((reply) => {
                if (reply.FileAttachments) {
                    reply.FileAttachments.forEach((attachment) => newFileAttachments.push(attachment));
                }
            });

            newFileAttachments.forEach((fileAttachment) => {
                fileAttachments[fileAttachment.id] = { ...fileAttachment };
            });

            return fileAttachments;
        }
        default:
            return state;
    }
};

export default fileAttachments;
