import {
    BILLING_CARD_MODAL_HIDE,
    BILLING_CARD_MODAL_SHOW,
    BILLING_COUPON_MODAL_HIDE,
    BILLING_COUPON_MODAL_SHOW,
    BILLING_DATA_SET,
    BILLING_EMAIL_MODAL_HIDE,
    BILLING_EMAIL_MODAL_SHOW,
    BILLING_INVOICE_SELECT,
    BILLING_OFFBOARDING_SURVEY_MODAL_HIDE,
    BILLING_OFFBOARDING_SURVEY_MODAL_SHOW,
    BILLING_PLAN_FOR_SWITCH_SELECT,
    BILLING_PLAN_SET,
    BILLING_SWITCH_PLAN_MODAL_HIDE,
    BILLING_SWITCH_PLAN_MODAL_SHOW,
    BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE,
    BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW,
    BILLING_UPGRADE_TO_PAID_MODAL_HIDE,
    BILLING_UPGRADE_TO_PAID_MODAL_SHOW
} from './types';

const billingDefaultState = {
    cardModalOpen: false,
    couponModalOpen: false,
    customer: null,
    emailModalOpen: false,
    invoices: [],
    loaded: false,
    newPlanForSwitch: null,
    offboardingSurveyModalOpen: false,
    oldPlanForSwitch: null,
    plan: null,
    selectedInvoiceId: null,
    switchPlanModalOpen: false,
    switchPlanSuccessModalOpen: false,
    upcomingInvoice: null,
    upgradeToPaidModalOpen: false
};

const billing = (state = billingDefaultState, action) => {
    switch (action.type) {
        case BILLING_DATA_SET:
            return { loaded: true, ...state, ...action.payload };
        case BILLING_INVOICE_SELECT:
            return { ...state, selectedInvoiceId: action.payload.invoiceId };
        case BILLING_CARD_MODAL_SHOW:
            return { ...state, cardModalOpen: true };
        case BILLING_CARD_MODAL_HIDE:
            return { ...state, cardModalOpen: false };
        case BILLING_COUPON_MODAL_SHOW:
            return { ...state, couponModalOpen: true };
        case BILLING_COUPON_MODAL_HIDE:
            return { ...state, couponModalOpen: false };
        case BILLING_PLAN_SET:
            return { ...state, plan: action.payload.plan };
        case BILLING_PLAN_FOR_SWITCH_SELECT:
            return {
                ...state,
                oldPlanForSwitch: action.payload.oldPlan,
                newPlanForSwitch: action.payload.newPlan
            };
        case BILLING_SWITCH_PLAN_MODAL_SHOW:
            return { ...state, switchPlanModalOpen: true };
        case BILLING_SWITCH_PLAN_MODAL_HIDE:
            return { ...state, switchPlanModalOpen: false };
        case BILLING_SWITCH_PLAN_SUCCESS_MODAL_SHOW:
            return { ...state, switchPlanSuccessModalOpen: true };
        case BILLING_SWITCH_PLAN_SUCCESS_MODAL_HIDE:
            return { ...state, switchPlanSuccessModalOpen: false };
        case BILLING_UPGRADE_TO_PAID_MODAL_SHOW:
            return { ...state, upgradeToPaidModalOpen: true };
        case BILLING_UPGRADE_TO_PAID_MODAL_HIDE:
            return { ...state, upgradeToPaidModalOpen: false };
        case BILLING_OFFBOARDING_SURVEY_MODAL_SHOW:
            return { ...state, offboardingSurveyModalOpen: true };
        case BILLING_OFFBOARDING_SURVEY_MODAL_HIDE:
            return { ...state, offboardingSurveyModalOpen: false };
        case BILLING_EMAIL_MODAL_SHOW:
            return { ...state, emailModalOpen: true };
        case BILLING_EMAIL_MODAL_HIDE:
            return { ...state, emailModalOpen: false };
        default:
            return state;
    }
};

export default billing;
