import cloneDeep from 'lodash/cloneDeep';
import API from 'lib/api';
import {
    ARCHIVED_CANVASES_COUNT_SET,
    DASHBOARD_CANVAS_FILTER_TYPE_SET,
    DASHBOARD_CANVASES_BY_NAME_SORT,
    DASHBOARD_CANVASES_BY_RECENCY_SORT,
    DASHBOARD_FILTERED_CANVASES_SET,
    DASHBOARD_EXPANDED_FOLDER_ID_ADD,
    DASHBOARD_EXPANDED_FOLDER_ID_REMOVE,
    DASHBOARD_FOLDERS_NESTED_LIST_SET,
    DASHBOARD_SEARCH_TERM_SET,
    DASHBOARD_SELECTED_FOLDER_SET,
    DASHBOARD_SIDEBAR_WIDTH_SET,
    DASHBOARD_TEAM_INVITE_SET,
    DASHBOARD_VISITED_FOLDER_ID_ADD,
    FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_DELETE,
    FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET,
    MODAL_CANVAS_ENTER_CREDENTIALS_HIDE,
    MODAL_CANVAS_ENTER_CREDENTIALS_SHOW,
    MODAL_CANVAS_EXPIRY_HIDE,
    MODAL_CANVAS_EXPIRY_SHOW,
    MODAL_CANVAS_REMINDERS_HIDE,
    MODAL_CANVAS_REMINDERS_SHOW,
    MODAL_CREATE_EMAIL_CANVAS_HIDE,
    MODAL_CREATE_EMAIL_CANVAS_SHOW,
    MODAL_CREATE_WEBSITE_CANVAS_HIDE,
    MODAL_CREATE_WEBSITE_CANVAS_SHOW,
    MODAL_DELETE_FOLDER_HIDE,
    MODAL_DELETE_FOLDER_SHOW,
    MODAL_DESIGN_CANVAS_UPLOAD_HIDE,
    MODAL_DESIGN_CANVAS_UPLOAD_SHOW,
    MODAL_EDIT_CANVAS_HIDE,
    MODAL_EDIT_CANVAS_SHOW,
    MODAL_EDIT_FOLDER_HIDE,
    MODAL_EDIT_FOLDER_SHOW,
    MODAL_MOVE_CANVAS_TO_FOLDER_HIDE,
    MODAL_MOVE_CANVAS_TO_FOLDER_SHOW,
    MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE,
    MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW,
    MODAL_SET_FEEDBACK_DEADLINE_HIDE,
    MODAL_SET_FEEDBACK_DEADLINE_SHOW,
    MODAL_SHARE_FOLDER_HIDE,
    MODAL_SHARE_FOLDER_SHOW,
    MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE,
    MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW,
    MODAL_TEAM_INVITE_HIDE,
    MODAL_TEAM_INVITE_SHOW,
    MODAL_TRIAL_EXPIRED_HIDE,
    MODAL_TRIAL_EXPIRED_SHOW
} from './types';

export function setFilteredDashboardCanvases(canvasIds) {
    return {
        type: DASHBOARD_FILTERED_CANVASES_SET,
        payload: { canvasIds }
    };
}

export function setDashboardSearch(search) {
    return {
        type: DASHBOARD_SEARCH_TERM_SET,
        payload: { search }
    };
}

export function setDashboardFoldersNestedList(list) {
    return {
        type: DASHBOARD_FOLDERS_NESTED_LIST_SET,
        payload: { list }
    };
}

export function generateDashboardFoldersNestedList() {
    return (dispatch, getState) => {
        const { folders } = getState();
        let updatedFolders = Object.values(cloneDeep(folders));

        updatedFolders = updatedFolders.filter((folder) => {
            if (!folder.children) {
                // eslint-disable-next-line no-param-reassign
                folder.children = [];
            }

            if (folder.parentFolderId) {
                const parentFolder = updatedFolders.find(({ id }) => id === folder.parentFolderId);

                if (parentFolder) {
                    if (!parentFolder.children) {
                        parentFolder.children = [];
                    }

                    parentFolder.children.push(folder);
                }
            }

            // Return folders with no parentFolderId
            return !folder.parentFolderId;
        });

        const list = updatedFolders.map((folder) => cleanFolderProperties(folder));
        dispatch(setDashboardFoldersNestedList(list));
    };
}

export function cleanFolderProperties(folder) {
    return {
        canvasesCount: folder.canvasesCount,
        name: folder.name,
        visibility: folder.visibility,
        children: folder.children.map((folder) => cleanFolderProperties(folder)),
        id: folder.id
    };
}

export function addDashboardExpandedFolderId(id) {
    return {
        type: DASHBOARD_EXPANDED_FOLDER_ID_ADD,
        payload: { id }
    };
}

export function removeDashboardExpandedFolderId(id) {
    return {
        type: DASHBOARD_EXPANDED_FOLDER_ID_REMOVE,
        payload: { id }
    };
}

export function setDashboardInitialSidebarWidth(width) {
    return {
        type: DASHBOARD_SIDEBAR_WIDTH_SET,
        payload: { width }
    };
}

export function setDashboardCanvasFilterAll() {
    return {
        type: DASHBOARD_CANVAS_FILTER_TYPE_SET,
        payload: { canvasFilterType: 'all' }
    };
}

export function setDashboardCanvasFilterMine() {
    return {
        type: DASHBOARD_CANVAS_FILTER_TYPE_SET,
        payload: { canvasFilterType: 'mine' }
    };
}

export function setDashboardCanvasFilterNewActivity() {
    return {
        type: DASHBOARD_CANVAS_FILTER_TYPE_SET,
        payload: { canvasFilterType: 'new-activity' }
    };
}

export function setDashboardCanvasFilterArchived() {
    return {
        type: DASHBOARD_CANVAS_FILTER_TYPE_SET,
        payload: { canvasFilterType: 'archived' }
    };
}

export function setDashboardCanvasFilterNone() {
    return {
        type: DASHBOARD_CANVAS_FILTER_TYPE_SET,
        payload: { canvasFilterType: 'none' }
    };
}

export function sortDashboardCanvasesByRecency() {
    return {
        type: DASHBOARD_CANVASES_BY_RECENCY_SORT,
        payload: null
    };
}

export function sortDashboardCanvasesByName() {
    return {
        type: DASHBOARD_CANVASES_BY_NAME_SORT,
        payload: null
    };
}

export function setDashboardSelectedFolder(folderId) {
    return {
        type: DASHBOARD_SELECTED_FOLDER_SET,
        payload: { folderId }
    };
}

export function addDashboardVisitedFolderId(folderId) {
    return {
        type: DASHBOARD_VISITED_FOLDER_ID_ADD,
        payload: { folderId }
    };
}

export function clearDashboardSelectedFolder() {
    return {
        type: DASHBOARD_SELECTED_FOLDER_SET,
        payload: { folderId: null }
    };
}

export function setDashboardTeamInvite(inviteId) {
    return {
        type: DASHBOARD_TEAM_INVITE_SET,
        payload: { inviteId }
    };
}

export function hideDesignCanvasUploadModal() {
    return {
        type: MODAL_DESIGN_CANVAS_UPLOAD_HIDE,
        payload: null
    };
}

export function showDesignCanvasUploadModal(assetType = null) {
    return {
        type: MODAL_DESIGN_CANVAS_UPLOAD_SHOW,
        payload: { assetType }
    };
}

export function hideCanvasEnterCredentialsModal() {
    return {
        type: MODAL_CANVAS_ENTER_CREDENTIALS_HIDE,
        payload: null
    };
}

export function showCanvasEnterCredentialsModal(url) {
    return {
        type: MODAL_CANVAS_ENTER_CREDENTIALS_SHOW,
        payload: { url }
    };
}

export function showCanvasEditModal(canvasId) {
    return {
        type: MODAL_EDIT_CANVAS_SHOW,
        payload: { canvasId }
    };
}

export function hideCanvasEditModal() {
    return {
        type: MODAL_EDIT_CANVAS_HIDE,
        payload: null
    };
}

export function showCanvasRemindersModal(canvasId) {
    return {
        type: MODAL_CANVAS_REMINDERS_SHOW,
        payload: { canvasId }
    };
}

export function hideCanvasRemindersModal() {
    return {
        type: MODAL_CANVAS_REMINDERS_HIDE,
        payload: null
    };
}

export function showCanvasExpiryModal(canvasId) {
    return {
        type: MODAL_CANVAS_EXPIRY_SHOW,
        payload: { canvasId }
    };
}

export function hideCanvasExpiryModal() {
    return {
        type: MODAL_CANVAS_EXPIRY_HIDE,
        payload: null
    };
}

export function showFeedbackDeadlineModal(canvasId) {
    return {
        type: MODAL_SET_FEEDBACK_DEADLINE_SHOW,
        payload: { canvasId }
    };
}

export function hideFeedbackDeadlineModal() {
    return {
        type: MODAL_SET_FEEDBACK_DEADLINE_HIDE,
        payload: null
    };
}

export function showSelectCanvasForDeadlineRemindersModal() {
    return {
        type: MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_SHOW,
        payload: null
    };
}

export function hideSelectCanvasForDeadlineRemindersModal() {
    return {
        type: MODAL_SELECT_CANVAS_FOR_DEADLINE_REMINDERS_HIDE,
        payload: null
    };
}

export function showSwitchCanvasToPremiumModal(canvasId) {
    return {
        type: MODAL_SWITCH_CANVAS_TO_PREMIUM_SHOW,
        payload: { canvasId }
    };
}

export function hideSwitchCanvasToPremiumModal() {
    return {
        type: MODAL_SWITCH_CANVAS_TO_PREMIUM_HIDE,
        payload: null
    };
}

export function showTrialExpiredModal() {
    return {
        type: MODAL_TRIAL_EXPIRED_SHOW,
        payload: null
    };
}

export function hideTrialExpiredModal() {
    return {
        type: MODAL_TRIAL_EXPIRED_HIDE,
        payload: null
    };
}

export function showMoveCanvasToFolderModal(canvasId) {
    return {
        type: MODAL_MOVE_CANVAS_TO_FOLDER_SHOW,
        payload: { canvasId }
    };
}

export function hideMoveCanvasToFolderModal() {
    return {
        type: MODAL_MOVE_CANVAS_TO_FOLDER_HIDE,
        payload: null
    };
}

export function showShareFolderModal() {
    return {
        type: MODAL_SHARE_FOLDER_SHOW,
        payload: null
    };
}

export function hideShareFolderModal() {
    return {
        type: MODAL_SHARE_FOLDER_HIDE,
        payload: null
    };
}

export function showFolderEditModal(folderId) {
    return {
        type: MODAL_EDIT_FOLDER_SHOW,
        payload: { folderId }
    };
}

export function hideFolderEditModal() {
    return {
        type: MODAL_EDIT_FOLDER_HIDE,
        payload: null
    };
}

export function showFolderDeleteModal(folderId) {
    return {
        type: MODAL_DELETE_FOLDER_SHOW,
        payload: { folderId }
    };
}

export function hideFolderDeleteModal() {
    return {
        type: MODAL_DELETE_FOLDER_HIDE,
        payload: null
    };
}

export function hideTeamInviteModal() {
    return {
        type: MODAL_TEAM_INVITE_HIDE,
        payload: null
    };
}

export function showTeamInviteModal() {
    return {
        type: MODAL_TEAM_INVITE_SHOW,
        payload: null
    };
}

export function showCreateWebsiteCanvasModal() {
    return {
        type: MODAL_CREATE_WEBSITE_CANVAS_SHOW,
        payload: null
    };
}

export function hideCreateWebsiteCanvasModal() {
    return {
        type: MODAL_CREATE_WEBSITE_CANVAS_HIDE,
        payload: null
    };
}

export function showCreateEmailCanvasModal() {
    return {
        type: MODAL_CREATE_EMAIL_CANVAS_SHOW,
        payload: null
    };
}

export function hideCreateEmailCanvasModal() {
    return {
        type: MODAL_CREATE_EMAIL_CANVAS_HIDE,
        payload: null
    };
}

export function setFeedbackDeadlineModalOpenLocation(location) {
    return {
        type: FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_SET,
        payload: { location }
    };
}

export function deleteFeedbackDeadlineModalOpenLocation() {
    return {
        type: FEEDBACK_DEADLINE_MODAL_OPEN_LOCATION_DELETE,
        payload: null
    };
}

export function setNumOfArchivedCanvases(canvasesCount) {
    return {
        type: ARCHIVED_CANVASES_COUNT_SET,
        payload: { canvasesCount }
    };
}

export function getNumOfArchivedCanvases() {
    return (dispatch, getState) =>
        API.call(`/users/me/canvases/archived-count`, 'get', null, getState().auth).then((res) => {
            dispatch(setNumOfArchivedCanvases(res.archivedCanvasesCount));
        });
}
