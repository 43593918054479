import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { selectFeedbackLinkRevision, selectFeedbackLinkAnnotation } from '../ui-feedback-link/actions';
import { CANVAS_REVISIONS_ADD, CANVAS_REVISIONS_UPDATE, CANVAS_REVISIONS_REMOVE } from './types';
import { removeAnnotations } from '../annotations/actions';

export function addCanvasRevisions(canvasRevisions) {
    return {
        type: CANVAS_REVISIONS_ADD,
        payload: { canvasRevisions }
    };
}

export function removeCanvasRevisions(canvasRevisions) {
    return {
        type: CANVAS_REVISIONS_REMOVE,
        payload: { canvasRevisions }
    };
}

export function updateCanvasRevisions(canvasRevisions) {
    return {
        type: CANVAS_REVISIONS_UPDATE,
        payload: { canvasRevisions }
    };
}

export function createCanvasRevision(canvasRevision) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        const canvas = canvases[canvasRevision.CanvasId];
        return API.call(`/canvases/${canvasRevision.CanvasId}/revisions`, 'post', canvasRevision, auth).then(
            (canvasRevision) => {
                analytics.addCanvasRevision(canvas);
                dispatch(addCanvasRevisions([canvasRevision]));
                return canvasRevision;
            }
        );
    };
}

export function modifyCanvasRevision(canvasRevision) {
    return (dispatch, getState) =>
        API.call(`/canvas-revisions/${canvasRevision.id}`, 'put', canvasRevision, getState().auth).then(
            (canvasRevision) => {
                dispatch(updateCanvasRevisions([canvasRevision]));
                return canvasRevision;
            }
        );
}

export function realtimeDeletedCanvasRevision(deletedCanvasRevision) {
    return (dispatch, getState) => {
        const state = getState();
        const {
            annotations,
            canvasRevisions,
            ui: {
                feedbackLink: { selectedAnnotationId, revisionId: selectedRevisionId }
            }
        } = state;

        // Remove deleted canvas revision and sort remaining so latest version is first
        const remainingCanvasRevisions = Object.values(canvasRevisions)
            .filter((canvasRevision) => canvasRevision.id !== deletedCanvasRevision.id)
            .sort((a, b) => b.number - a.number);

        // Reset selected revision
        let newSelectedRevisionId = selectedRevisionId;
        if (deletedCanvasRevision.id === selectedRevisionId) {
            newSelectedRevisionId = remainingCanvasRevisions[0].id;
            dispatch(selectFeedbackLinkRevision(newSelectedRevisionId));
        }

        // Reset selected annotation
        const deletedRevisionAnnotations = Object.values(annotations).filter(
            (annotation) => annotation.CanvasRevisionId === deletedCanvasRevision.id
        );
        if (deletedRevisionAnnotations.map((annotation) => annotation.id === selectedAnnotationId)) {
            dispatch(selectFeedbackLinkAnnotation(null));
        }

        // Remove annotations that belong to deleted revision
        dispatch(removeAnnotations(deletedRevisionAnnotations));

        dispatch(removeCanvasRevisions([deletedCanvasRevision]));
    };
}

export function deleteCanvasRevision(canvasRevisionToDelete) {
    return (dispatch, getState) =>
        API.call(
            `/canvas-revisions/${canvasRevisionToDelete.id}`,
            'delete',
            canvasRevisionToDelete,
            getState().auth
        ).then((revision) => dispatch(realtimeDeletedCanvasRevision(revision)));
}
