import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { LABELS_ADD, LABELS_REMOVE, LABELS_UPDATE } from './types';

export function addLabels(labels = []) {
    return {
        type: LABELS_ADD,
        payload: { labels }
    };
}

export function updateLabels(labels = []) {
    return {
        type: LABELS_UPDATE,
        payload: { labels }
    };
}

export function removeLabels(labels = []) {
    return {
        type: LABELS_REMOVE,
        payload: { labels }
    };
}

export function createLabel(label) {
    return (dispatch, getState) =>
        API.call('/labels', 'post', label, getState().auth).then((label) => {
            analytics.createLabel(label);
            dispatch(addLabels([label]));
            return label;
        });
}

export function modifyLabel(label) {
    return (dispatch, getState) =>
        API.call(`/labels/${label.id}`, 'put', label, getState().auth).then((label) => {
            dispatch(updateLabels([label]));
            return label;
        });
}

export function deleteLabel(label) {
    return (dispatch, getState) =>
        API.call(`/labels/${label.id}`, 'delete', label, getState().auth).then((label) => {
            dispatch(removeLabels([label]));
            return label;
        });
}

export function fetchTeamLabels(teamId) {
    return (dispatch, getState) =>
        API.call(`/teams/${teamId}/labels`, 'get', null, getState().auth).then((labels) => {
            dispatch(addLabels(labels));
            return labels;
        });
}
