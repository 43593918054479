import { mentionsRegexFull } from './mentions-regex';

/**
 * Parses annotation/reply comment body for mentioned users.
 *
 * @export
 * @param {*} text
 * @return {[]{ id: string, name: string}}
 */
export default function parseMentions(text) {
    if (!text) {
        return [];
    }

    let results;
    const mentions = {};
    
    // eslint-disable-next-line no-cond-assign
    while ((results = mentionsRegexFull.exec(text)) !== null) {
        const name = results[1];
        const id = Number(results[2]);
        mentions[id] = { id, name };
    }

    return Object.keys(mentions).map((id) => mentions[id]);
}
