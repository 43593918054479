import API from 'lib/api';
import { PLANS_ADD } from './types';

export function addPlans(plans = []) {
    return {
        type: PLANS_ADD,
        payload: { plans }
    };
}

export function fetchPlans() {
    return (dispatch, getState) => {
        const { auth } = getState();
        return API.call('/plans', 'get', null, auth).then((plans) => {
            dispatch(addPlans(plans));
            return plans;
        });
    };
}
