import { NotificationManager as notifications } from 'react-notifications';
import API from 'lib/api';
import * as analytics from 'lib/analytics';
import { toasts } from 'components/Toasts';
import { FOLDERS_ADD, FOLDERS_REMOVE, FOLDERS_UPDATE } from './types';
import { updateCanvases, addCanvases } from '../canvases/actions';
import { setDashboardFolders, addDashboardFolders, removeDashboardFolders } from '../dashboard-folders/actions';
import { addDashboardVisitedFolderId } from '../ui-dashboard/actions';
import { addDashboardCanvases } from '../dashboard-canvases/actions';

export function addFolders(folders) {
    return {
        type: FOLDERS_ADD,
        payload: { folders }
    };
}

export function removeFolders(folders) {
    return {
        type: FOLDERS_REMOVE,
        payload: { folders }
    };
}

export function updateFolders(folders) {
    return {
        type: FOLDERS_UPDATE,
        payload: { folders }
    };
}

export function getDashboardFolders() {
    return (dispatch, getState) => {
        const { auth } = getState();

        return API.call('/users/me/folders', 'get', null, auth)
            .then((folders) => dispatch(addFolders(folders)))
            .then(({ payload: { folders } }) => dispatch(setDashboardFolders(folders)));
    };
}

export function createFolder(name, parentFolderId = null) {
    return (dispatch, getState) => {
        const { auth } = getState();

        return API.call('/folders', 'post', { name, parentFolderId }, auth).then((folder) => {
            analytics.createFolder(folder);
            dispatch(addFolders([folder]));
            dispatch(addDashboardFolders([folder]));
            return folder;
        });
    };
}

export function moveCanvasToFolder(canvasId, folderId, isUndo = false) {
    return (dispatch, getState) => {
        const { auth, canvases, folders } = getState();
        const folder = folders[folderId];
        const canvas = canvases[canvasId];

        if (canvas && canvas.FolderId === folderId) {
            return null;
        }

        const oldFolderId = canvas ? canvas.FolderId : null;

        return API.call(`/folders/${folderId}/canvases/${canvasId}`, 'put', null, auth).then((canvas) => {
            dispatch(updateCanvases([canvas]));

            const oldFolder = oldFolderId ? folders[oldFolderId] : null;
            analytics.addCanvasToFolder(canvas, folder, oldFolder);
            if (oldFolder) {
                analytics.removeCanvasFromFolder(canvas, oldFolder, folder);
            }

            if (!isUndo) {
                toasts.create({
                    type: 'info',
                    message: (
                        <span>
                            Moved to <strong>{folder.name}</strong>
                        </span>
                    ),
                    action: {
                        name: 'Undo',
                        onClick: () => {
                            if (oldFolderId) {
                                dispatch(moveCanvasToFolder(canvasId, oldFolderId, true));
                            } else {
                                dispatch(removeCanvasFromFolder(canvasId, folderId, true));
                            }
                        }
                    }
                });
            }

            return canvas;
        });
    };
}

export function removeCanvasFromFolder(canvasId, folderId, isUndo = false) {
    return (dispatch, getState) => {
        const { auth, folders } = getState();
        const folder = folders[folderId];

        return API.call(`/folders/${folderId}/canvases/${canvasId}`, 'delete', null, auth).then((canvas) => {
            dispatch(updateCanvases([canvas]));
            analytics.removeCanvasFromFolder(canvas, folder);

            if (!isUndo) {
                toasts.create({
                    type: 'info',
                    message: (
                        <span>
                            Removed from <strong>{folder.name}</strong> project
                        </span>
                    ),
                    action: {
                        name: 'Undo',
                        onClick: () => dispatch(moveCanvasToFolder(canvasId, folderId, true))
                    }
                });
            }

            return canvas;
        });
    };
}

export function getFolderByShareableId(shareableId, password = null) {
    return (dispatch, getState) =>
        API.call(
            `/folders?shareableId=${shareableId}${password ? `&password=${password}` : ''}`,
            'get',
            null,
            getState().auth
        ).then((folder) => {
            dispatch(addFolders([folder]));
            dispatch(addDashboardFolders([folder]));
            return folder;
        });
}

export function getCanvasesByFolderId(folderId, password = null) {
    return async (dispatch, getState) => {
        const { ui } = getState();

        // If user has already visited this folder, don't download same canvases again
        if (ui.dashboard.visitedFolderIds.includes(folderId)) {
            return;
        }

        const canvases = await API.call(
            `/folders/${folderId}/canvases${password ? `?password=${password}` : ''}`,
            'get',
            null,
            getState().auth
        );

        dispatch(addDashboardVisitedFolderId(folderId));
        dispatch(addCanvases(canvases, true));
        dispatch(addDashboardCanvases(canvases));
    };
}

export function modifyFolder(data) {
    return (dispatch, getState) =>
        API.call(`/folders/${data.id}`, 'put', data, getState().auth).then((folder) =>
            dispatch(updateFolders([folder]))
        );
}

export function deleteFolder(folderId) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        const numCanvasesInFolder = Object.values(canvases).filter((canvas) => canvas.FolderId === folderId).length;

        return API.call(`/folders/${folderId}`, 'delete', null, auth).then((folder) => {
            analytics.deleteFolder(folder, numCanvasesInFolder);
            dispatch(removeDashboardFolders([folder]));
            return dispatch(removeFolders([folder]));
        });
    };
}

export function shareFolder(folderId, emails, message = null, source = null) {
    return (_dispatch, getState) => {
        const { folders, auth } = getState();
        const folder = folders[folderId];

        return API.call(`/folders/${folderId}/share`, 'post', { emails, message }, auth).then(() => {
            analytics.shareFolder(folder, 'email', emails.length, message, source);
            notifications.success(
                `${emails.map((email) => email.email).join(', ')}.`,
                `Invite${emails.length === 1 ? '' : 's'} sent to`,
                5000
            );
        });
    };
}
