import { DASHBOARD_FOLDERS_REMOVE, DASHBOARD_FOLDERS_SET, DASHBOARD_FOLDERS_ADD } from './types';

export function setDashboardFolders(folders = []) {
    return {
        type: DASHBOARD_FOLDERS_SET,
        payload: { folders }
    };
}

export function addDashboardFolders(folders = []) {
    return {
        type: DASHBOARD_FOLDERS_ADD,
        payload: { folders }
    };
}

export function removeDashboardFolders(folders = []) {
    return {
        type: DASHBOARD_FOLDERS_REMOVE,
        payload: { folders }
    };
}
