import { NotificationManager as notifications } from 'react-notifications';
import { highlightMentionsText as highlightMentions } from 'lib/highlight-mentions';
import API from 'lib/api';
import flags from 'lib/flags';
import * as analytics from 'lib/analytics';
import { ANNOTATIONS_ADD, ANNOTATIONS_REMOVE, ANNOTATIONS_UPDATE } from './types';
import { selectFeedbackLinkAnnotation, openFeedbackLinkSidebar } from '../ui-feedback-link/actions';

export function addAnnotations(annotations = []) {
    return {
        type: ANNOTATIONS_ADD,
        payload: { annotations }
    };
}

export function updateAnnotations(annotations = []) {
    return {
        type: ANNOTATIONS_UPDATE,
        payload: { annotations }
    };
}

export function removeAnnotations(annotations = []) {
    return {
        type: ANNOTATIONS_REMOVE,
        payload: { annotations }
    };
}

export function getAnnotations(canvasId) {
    return (dispatch, getState) =>
        API.call(`/canvases/${canvasId}/annotations`, 'get', null, getState().auth).then((annotations) =>
            dispatch(addAnnotations(annotations))
        );
}

export function createAnnotation(annotation) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        return API.call('/annotations', 'post', annotation, auth).then((annotation) => {
            const canvas = canvases[annotation.CanvasId];
            dispatch(addAnnotations([annotation]));

            analytics.createComment(annotation, canvas);
            notifications.success(
                highlightMentions(annotation.comment),
                `You left a ${annotation.isPrivate ? 'private' : ''} comment`,
                5000,
                () => {
                    dispatch(selectFeedbackLinkAnnotation(annotation.id));
                    dispatch(openFeedbackLinkSidebar());
                }
            );

            return annotation;
        });
    };
}

export function modifyAnnotation(annotation, shortcutUsed = false) {
    return (dispatch, getState) => {
        const { auth, canvases, annotations } = getState();
        const commentStatusChanged = Object.keys(annotation).includes('status');
        const commentChanged = Object.keys(annotation).includes('comment');

        return API.call(`/annotations/${annotation.id}`, 'put', annotation, auth).then((annotation) => {
            const canvas = canvases[annotation.CanvasId];
            if (commentStatusChanged) {
                const oldStatus = annotations[annotation.id]?.status;
                analytics.changeCommentStatus(annotation, canvas, shortcutUsed, oldStatus);
            } else if (commentChanged) {
                analytics.editComment(annotation, canvas);
            }
            return dispatch(updateAnnotations([annotation]));
        });
    };
}

export function deleteAnnotation(annotation) {
    return (dispatch, getState) => {
        const { auth, canvases } = getState();
        return API.call(`/annotations/${annotation.id}`, 'delete', annotation, auth).then((annotation) => {
            const canvas = canvases[annotation.CanvasId];
            analytics.deleteComment(annotation, canvas);
            return dispatch(removeAnnotations([annotation]));
        });
    };
}

export function setCanvasAnnotationsActiveOnPath(canvasId, path) {
    return (dispatch, getState) => {
        const { annotations } = getState();
        const annotationsList = Object.values(annotations);

        const annotationsToSetActive = annotationsList
            .filter((annotation) => annotation.CanvasId === canvasId && annotation.path === path)
            .map((annotation) => ({ ...annotation, inactiveReason: null }));

        return dispatch(updateAnnotations(annotationsToSetActive));
    };
}

export function realtimeCreatedAnnotation(annotation) {
    return (dispatch, getState) => {
        dispatch(addAnnotations([annotation]));

        const {
            canvases,
            ui,
            auth: { user }
        } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];

        // Don't display notifications for private comments.
        if (annotation.isPrivate && !flags.userHasPrivateCommentsEnabled(user, canvas)) {
            return;
        }

        notifications.info(
            highlightMentions(annotation.comment),
            `${annotation.userName} left a new comment`,
            5000,
            () => {
                dispatch(selectFeedbackLinkAnnotation(annotation.id));
                dispatch(openFeedbackLinkSidebar());
            }
        );
    };
}

export function realtimeUpdatedAnnotation(annotation) {
    return updateAnnotations([annotation]);
}

export function setAnnotationInactiveReason(annotationId, inactiveReason) {
    return (dispatch, getState) => {
        const state = getState();
        const annotation = state.annotations[annotationId];

        if (!annotation) {
            return null;
        }

        return dispatch(updateAnnotations([{ ...annotation, inactiveReason }]));
    };
}

export function addLabelToAnnotation(labelId, annotationId) {
    return (dispatch, getState) => {
        const { auth, labels, canvases } = getState();
        const label = labels[labelId];
        return API.call(`/annotations/${annotationId}/labels/${labelId}`, 'post', null, auth).then((annotation) => {
            const canvas = canvases[annotation.CanvasId];
            analytics.addLabel(label, annotation, canvas);
            dispatch(updateAnnotations([annotation]));
            return annotation;
        });
    };
}

export function removeLabelFromAnnotation(labelId, annotationId) {
    return (dispatch, getState) => {
        const { auth, labels, canvases } = getState();
        const label = labels[labelId];
        return API.call(`/annotations/${annotationId}/labels/${labelId}`, 'delete', null, auth).then((annotation) => {
            const canvas = canvases[annotation.CanvasId];
            analytics.removeLabel(label, annotation, canvas);
            dispatch(updateAnnotations([annotation]));
            return annotation;
        });
    };
}

export function assignUserToAnnotation(userId, annotationId) {
    return (dispatch, getState) =>
        API.call(`/annotations/${annotationId}/assigned-user/${userId}`, 'post', null, getState().auth).then(
            (annotation) => {
                dispatch(updateAnnotations([annotation]));
                return annotation;
            }
        );
}

export function unassignUserFromAnnotation(annotationId) {
    return (dispatch, getState) =>
        API.call(`/annotations/${annotationId}/assigned-user`, 'delete', null, getState().auth).then((annotation) => {
            dispatch(updateAnnotations([annotation]));
            return annotation;
        });
}

export function exportAnnotationsToTrello({ listId, annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call('/users/me/accounts/trello/sync', 'post', { listId, annotationIds }, auth).then(() => {
            analytics.exportComments(canvas, 'trello', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    };
}

export function exportAnnotationsToAsana({ workspaceId, projectId, sectionId, taskId, annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            '/users/me/accounts/asana/sync',
            'post',
            {
                projectId,
                workspaceId,
                taskId,
                annotationIds,
                sectionId: sectionId === 'none' ? null : sectionId
            },
            auth
        ).then(() => {
            analytics.exportComments(canvas, 'asana', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    };
}

export function exportAnnotationsToMonday({ boardId, groupId, annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            '/users/me/accounts/monday/sync',
            'post',
            {
                boardId,
                groupId,
                annotationIds
            },
            auth
        ).then(() => {
            analytics.exportComments(canvas, 'monday', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    };
}

export function exportAnnotationsToJira({ projectId, annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            '/users/me/accounts/jira/sync',
            'post',
            {
                projectId,
                annotationIds
            },
            auth
        ).then((result) => {
            analytics.exportComments(canvas, 'jira', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
            return result;
        });
    };
}

export function exportAnnotationsToWebhook({ annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            '/users/me/accounts/webhook/sync',
            'post',
            {
                annotationIds
            },
            auth
        ).then(() => {
            analytics.exportComments(canvas, 'webhook', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    };
}

export function exportAnnotationsToClickup({ listId, taskId, annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            '/users/me/accounts/clickup/sync',
            'post',
            {
                listId,
                taskId,
                annotationIds
            },
            auth
        ).then(() => {
            analytics.exportComments(canvas, 'clickup', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    }; 
}

export function markAnnotationsAsExported({ annotationIds }) {
    return (dispatch, getState) => {
        const { auth, canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        return API.call(
            `/canvases/${canvas.id}/annotations/mark-as-exported`,
            'post',
            {
                annotationIds
            },
            auth
        ).then(() => {
            analytics.exportComments(canvas, 'csv', annotationIds.length);
            dispatch(updateAnnotations(annotationIds.map((id) => ({ id, lastExportDate: new Date().toString() }))));
        });
    };
}
