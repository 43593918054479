import { DASHBOARD_CANVASES_ADD, DASHBOARD_CANVASES_SET, DASHBOARD_CANVASES_REMOVE } from './types';

export function setDashboardCanvases(canvases) {
    return {
        type: DASHBOARD_CANVASES_SET,
        payload: { canvases }
    };
}

/**
 * Add new canvases to dashboard.
 *
 * @export
 * @param {*} canvases
 * @param {string} [location='start'] | Whether to add the new canvases to the start or the end of the list.
 * @return {*} 
 */
export function addDashboardCanvases(canvases, location='start') {
    return {
        type: DASHBOARD_CANVASES_ADD,
        payload: { canvases, location }
    };
}

export function removeDashboardCanvases(canvases) {
    return {
        type: DASHBOARD_CANVASES_REMOVE,
        payload: { canvases }
    };
}
