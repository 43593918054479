const mobileScreenSizes = [
    { width: 320, name: 'iPhone 5' },
    { width: 360, name: 'Galaxy S22' },
    { width: 375, name: 'iPhone 7/8/X' },
    { width: 384, name: 'Galaxy S22 Ultra' },
    { width: 390, name: 'iPhone 12/13/14' },
    { width: 412, name: 'Pixel 6' },
    { width: 414, name: 'iPhone 11/XR/XS' },
    { width: 430, name: 'iPhone 15 Plus'},
    { width: 768, name: 'iPad' }
];

export default mobileScreenSizes;
