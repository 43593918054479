import React from 'react';
import reactStringReplace from 'react-string-replace';
import { mentionsRegex } from './mentions-regex';

export function highlightMentionsText(text, prefix = '') {
    if (!text) {
        return text;
    }

    return text.replace(mentionsRegex, (match, p1) => `${prefix}${p1}`);
}

export function highlightMentionsReact(text) {
    if (!text) {
        return text;
    }

    return reactStringReplace(text, mentionsRegex, (match, i) => (
        <span className="mention" key={match + i}>
            {match}
        </span>
    ));
}
